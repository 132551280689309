import React from 'react';
import { useTranslation } from 'react-i18next';
import ArchivedTag from '../../components/ArchivedTag';
import { CategoryListItemDto } from '../../service/dto/category.dto';

interface ITableProps {
  list: Array<CategoryListItemDto>;
  onEdit: Function;
}

const CategoryTable = ({ list, onEdit }: ITableProps) => {
  const { t } = useTranslation();

  return (
    <table className='dataTable table-fixed w-full bg-white px-4'>
      <thead>
        <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter'>
          <td className='py-4 w-1/3'>{t('table.categoryName')}</td>
          <td className='text-gray-dark font-bold py-4 w-1/3'>{t('table.categoryDescription')}</td>
          <td className='text-gray-light py-4 w-1/5 xl:w-2/12'>{t('table.actions')}</td>
        </tr>
      </thead>
      <tbody>
        {list.map((i, j) => {
          return (
            <tr className='border border-solid border-black-divider' key={'item_' + j}>
              <td className='text-gray-dark font-bold py-4'>
                {i.name} {i.archived && <ArchivedTag />}
              </td>
              <td className='text-gray-dark py-4'>{i.description}</td>
              <td className='py-4 flex justify-between flex-wrap'>
                <span className='text-primary cursor-pointer pr-2' onClick={() => onEdit(j)}>
                  {t('buttons.edit')}
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CategoryTable;
