import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CaseService } from '../../service/case.service';
import { CaseResponseDto, UpdateCaseRequestDto } from '../../service/dto/case.dto';
import { CASE_STATE, CaseScoringFloat } from '../../common/enums';
import InputLabel from '../../components/InputLabel';
import TextInput from '../../components/TextInput';
import Textarea from '../../components/Textarea';
import MessageBlock from '../../components/MessageBlock';
import PrimaryButton from '../../components/PrimaryButton';

import EntitySelect, { IEntitySelectItem } from '../../components/EntitySelect/EntitySelect';

import Alert from '../../utils/alert';
import AbstractModal from '../../components/AbstractModal';
import {
  ErrorDictionary,
  PublishCaseRequestValidation,
  SaveCaseRequestValidation,
} from '../../validation/case.validation';
import CustomSelect, { ICustomSelectItem } from '../../components/CustomSelect';
import { CategoryService } from '../../service/category.service';
import { CategoryListItemDto } from '../../service/dto/category.dto';
import RadioInput, { IRadioItem, RadioInputStyle } from '../../components/RadioInput';

enum COMPONENT_STATE {
  CREATE,
  EDIT,
  NOT_FOUND,
}

let initialValue: UpdateCaseRequestDto = {
  id: '',
  name: '',
  description: '',
  author: '',
  interview_duration: 15,
  feedback_duration: 15,
  instructions: '',
  scoring_items: [],
  certified_coaches: [],
  scoring_items_positions: new Map<string, CaseScoringFloat>(),
  status: CASE_STATE.Draft,
  category: '',
  allowTeachableMoments: false,
};
let initialValueHash: string;

const CreateCaseForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState(COMPONENT_STATE.CREATE);
  const { id } = useParams<{ id: string }>();
  const [unpublish, setUnpublish] = useState<boolean>(false);

  const [data, setData] = useState<UpdateCaseRequestDto>(initialValue);
  const [errors, setErrors] = useState<ErrorDictionary>({});
  const [isUnsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [categoryList, setCategoryList] = useState<ICustomSelectItem[]>([]);

  const convertPopulateToIds = (caseData: CaseResponseDto): UpdateCaseRequestDto => {
    return {
      id: caseData.id,
      name: caseData.name,
      description: caseData.description,
      author: caseData.author,
      interview_duration: caseData.interview_duration,
      feedback_duration: caseData.feedback_duration,
      instructions: caseData.instructions,
      scoring_items: caseData.scoring_items.map(({ id }) => id),
      certified_coaches: caseData.certified_coaches.map(({ id }) => id),
      scoring_items_positions: caseData.scoring_items_positions
        ? new Map<string, CaseScoringFloat>(Object.entries(caseData.scoring_items_positions))
        : new Map<string, CaseScoringFloat>(),
      status: caseData.status,
      category: caseData.category,
      allowTeachableMoments: caseData.allowTeachableMoments,
    };
  };

  // CREATE or EDIT
  useEffect(() => {
    // IF id than Edit
    serviceCategoryFetch().then(_ => {
      if (id === undefined) {
        setState(COMPONENT_STATE.CREATE);
        initialValueHash = window.btoa(encodeURIComponent(JSON.stringify(data)));
      } else {
        setState(COMPONENT_STATE.EDIT);
        serviceDataFetch();
      }
    });
  }, [id]);

  const serviceCategoryFetch = (): Promise<unknown> => {
    return new Promise((resolve, reject) =>
      CategoryService.list({archived: false},
        (categories: CategoryListItemDto[]) => {
          setCategoryList(
            categories.map(category => {
              return { value: category.id, label: category.name };
            }),
          );

          if (id === undefined) {
            setData({ ...data, category: categories[0].id });
            initialValue.category = categories[0].id;
            initialValueHash = window.btoa(
              encodeURIComponent(
                JSON.stringify({
                  ...data,
                  category: categories[0].id,
                }),
              ),
            );
          }
          resolve(categories);
        },
        () => reject,
      ),
    );
  };

  // FETCH IF EDIT
  const serviceDataFetch = () => {
    CaseService.getById(
      id!,
      (data: CaseResponseDto) => {
        const newData = convertPopulateToIds(data);
        initialValueHash = window.btoa(encodeURIComponent(JSON.stringify(newData)));
        setData(newData);
      },
      error => {
        setState(COMPONENT_STATE.NOT_FOUND);
      },
    );
  };

  // SERVICE CREATE
  const serviceCreate = () => {
    CaseService.create(
      data,
      (data: CaseResponseDto) => {
        navigate('/case/edit/' + data.id);
        Alert.success(t('successMessages.caseCreated'));
      },
      error => {
        Alert.warning(t('errorMessages.caseNotCreated'));
        console.error(error);
      },
    );
  };

  const serviceUpdateSave = (caseState?: CASE_STATE) => {
    const prevCase = data.status;
    if (caseState) {
      data.status = caseState;
    }
    setData(data);
    CaseService.updateSave(
      id!,
      data,
      (newData: CaseResponseDto) => {
        Alert.success(t('successMessages.caseUpdated'));
        initialValueHash = window.btoa(encodeURIComponent(JSON.stringify(data)));
        setData(Object.assign({}, data));
      },
      error => {
        Alert.warning(t('errorMessages.caseNotUpdated'));
        if (prevCase) {
          data.status = prevCase;
        }
        setData(data);
      },
    );
  };

  const serviceUpdatePublish = (caseState?: CASE_STATE) => {
    const prevCase = data.status;
    if (caseState) {
      data.status = caseState;
    }
    setData(data);
    CaseService.updatePublish(
      id!,
      data,
      (newData: CaseResponseDto) => {
        Alert.success(t('successMessages.caseUpdated'));
        initialValueHash = window.btoa(encodeURIComponent(JSON.stringify(data)));
        setData(Object.assign({}, data));
      },
      error => {
        Alert.warning(t('errorMessages.caseNotUpdated'));
        if (prevCase) {
          data.status = prevCase;
        }
        setData(data);
      },
    );
  };

  const handleSubmit = (caseState: CASE_STATE = data.status) => {
    const isDataValid: boolean = validateData(caseState === CASE_STATE.Published);
    if (!isDataValid) {
      Alert.warning(t('cases.caseValidationError'));
      return;
    }
    data.status = caseState;
    data.scoring_items = data.scoring_items.filter((v: string) => !!v);
    data.certified_coaches = data.certified_coaches.filter((v: string) => !!v);
    if (state === COMPONENT_STATE.CREATE) {
      serviceCreate();
      return;
    }

    if (state == COMPONENT_STATE.EDIT) {
      if (caseState === CASE_STATE.Draft) {
        serviceUpdateSave(caseState);
      } else if (caseState === CASE_STATE.Published) {
        serviceUpdatePublish(caseState);
      }
    }
  };

  const handleChange = (fieldName: string, value: any) => {
    if (fieldName == 'scoring_items' && !value.length && !(data as any)[fieldName].length) {
      return;
    }
    const newData: any = Object.assign({}, data);
    if (fieldName == 'scoring_items') {
      newData[fieldName] = value.map((v: any) => v.title);
      value.forEach((v: any) => {
        v.title && newData.scoring_items_positions.set(v.title, v.position);
      });
    } else {
      newData[fieldName] = value;
    }
    setData(newData);
  };

  const handleUnpublish = () => {
    setUnpublish(!unpublish);
  };

  useEffect(() => {
    if (state == COMPONENT_STATE.EDIT && data.id && !categoryList.find(c => c.value == data.category)) {
      navigate('/dashboard');
      Alert.warning(t('errorMessages.redirectedToDashboard'));
    }
    detectChanges();
  }, [data]);

  const detectChanges = () => {
    setTimeout(() => {
      setUnsavedChanges(window.btoa(encodeURIComponent(JSON.stringify(data))) !== initialValueHash);
    }, 100);
  };

  const validateData = (publishAction: boolean = true) => {
    const validation: any = publishAction ? PublishCaseRequestValidation : SaveCaseRequestValidation;
    const newData: any = Object.assign({}, data);
    const newErrors: ErrorDictionary = {};
    setErrors(newErrors);
    for (const validationKey in validation) {
      if (
        (validation[validationKey].required && !newData[validationKey]) ||
        (validation[validationKey].minLength &&
          newData[validationKey].filter((v: string) => !!v).length < validation[validationKey].minLength)
      ) {
        newErrors[validationKey] = t(validation[validationKey].message);
      }
    }
    setErrors(Object.assign({}, newErrors));
    return Object.keys(newErrors).length === 0;
  };

  if (state == COMPONENT_STATE.NOT_FOUND) {
    return (
      <div className='container mt-4 text-center'>
        <MessageBlock message={t('errorMessages.loadingCase') + id} type='errorBlock' />
      </div>
    );
  }

  return (
    <div className='my-6 mx-4 2xl:mx-6'>
      <form className='w-full'>
        <div className='flex items-center justify-between py-2'>
          <div>
            {state == COMPONENT_STATE.CREATE && (
              <div className='font-bold text-lg text-black'>{t('cases.createCase')}</div>
            )}
            {state == COMPONENT_STATE.EDIT && <div className='font-bold text-lg text-black'>{t('cases.editCase')}</div>}
            {/* <div className="uppercase text-sm text-gray-dark"> {t('cases.createdBy')} {currentUser.firstName} {currentUser.lastName} </div> */}
          </div>

          <div className='flex items-center'>
            <PrimaryButton title={t('buttons.save')} icon='' className='mr-4' onClick={() => handleSubmit()} />
            {data.status === CASE_STATE.Published ? (
              <PrimaryButton
                title={t('buttons.unpublish')}
                icon='bi bi-check2-circle'
                onClick={() => handleUnpublish()}
              />
            ) : (
              <PrimaryButton
                title={t('buttons.publish')}
                icon='bi bi-check2-circle'
                onClick={() => handleSubmit(CASE_STATE.Published)}
              />
            )}
          </div>
        </div>
        <hr className='w-full h-px bg-black-divider border-0 mt-4 mb-6' />
        <div className='flex justify-between'>
          <div className='w-3/5 lg:w-7/12 xl:w-1/2'>
            <div className='mb-4'>
              <InputLabel label={t('inputLabels.category')} />
              <CustomSelect
                placeholder=''
                list={categoryList}
                value={(data && data.category) || ''}
                handleChange={(item: ICustomSelectItem) => {
                  if (state != COMPONENT_STATE.EDIT) {
                    handleChange('category', item.value);
                  }
                }}
                disabled={state == COMPONENT_STATE.EDIT}
              />
            </div>
            <div className='mb-4'>
              <InputLabel label={t('inputLabels.nameOfCase')} />
              <TextInput
                placeholder={t('placeholders.nameOfCase')}
                value={(data && data.name) || ''}
                handleChange={(e: any) => handleChange('name', e.target.value)}
                error={errors.name}
                required
              />
            </div>

            <div className='mb-4'>
              <InputLabel label={t('inputLabels.description')} />
              <Textarea
                placeholder={t('placeholders.caseDescription')}
                value={(data && data.description) || ''}
                handleChange={(e: any) => handleChange('description', e.target.value)}
                error={errors.description}
                required
              />
            </div>

            <div className='col-md-6 mb-4'>
              <InputLabel label={t('inputLabels.author')} additionalLabel={t('inputLabels.freeText')} />
              <TextInput
                inputType='text'
                placeholder={t('placeholders.author')}
                value={(data && data.author) || ''}
                handleChange={(e: any) => handleChange('author', e.target.value)}
                error={errors.author}
              />
            </div>

            <div className='col-md-6 mb-4'>
              <InputLabel label={t('inputLabels.interviewDuration')} additionalLabel={t('inputLabels.minutes')} />
              <TextInput
                inputType='number'
                placeholder={t('placeholders.tenMin')}
                value={data && data.interview_duration}
                minValue='5'
                maxValue='60'
                handleChange={(e: any) => handleChange('interview_duration', parseInt(e.target.value))}
                error={errors.interview_duration}
                required
              />
            </div>

            <div className='col-md-6 mb-4'>
              <InputLabel label={t('inputLabels.feedbackDuration')} additionalLabel={t('inputLabels.minutes')} />
              <TextInput
                inputType='number'
                placeholder={t('placeholders.fiveMin')}
                value={data && data.feedback_duration}
                minValue='0'
                maxValue='60'
                handleChange={(e: any) => handleChange('feedback_duration', parseInt(e.target.value))}
                error={errors.feedback_duration}
                required
              />
            </div>

            <div className='mb-4'>
              <InputLabel
                label={t('inputLabels.caseInstructions')}
                additionalLabel={t('inputLabels.traineeExamples')}
              />
              <Textarea
                placeholder={t('placeholders.caseInstructions')}
                value={(data && data.instructions) || ''}
                handleChange={(e: any) => handleChange('instructions', e.target.value)}
                error={errors.instructions}
                required
              />
            </div>

            <div className='font-bold text-lg text-black mb-2'>{t('inputLabels.teachableMoments')}</div>
            <div className='text-base mb-2'>{t('cases.activatingTeachableMoments')}</div>
            <RadioInput
              list={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
              ]}
              value={(data && data.allowTeachableMoments) || false}
              style={RadioInputStyle.HORIZONTAL}
              handleChange={(item: IRadioItem) => {
                handleChange('allowTeachableMoments', item.value);
              }}
            />

            <div className='font-bold text-lg text-black mb-2'>{t('inputLabels.scoringItems')}</div>
            <div className='text-base mb-6'>{t('cases.reordering')}</div>
            <EntitySelect
              entity='SCORING'
              values={
                data &&
                data.scoring_items.map(sI => {
                  let findPositionForScoring = data.scoring_items_positions.get(sI);
                  return { title: sI, position: findPositionForScoring || CaseScoringFloat.None };
                })
              }
              compareValuesBy={(item: any) => item.title}
              valuePropToShow={(item: any) => item.title}
              createItem={(value: string) => {
                return { title: value, position: CaseScoringFloat.None };
              }}
              handleChange={(items: IEntitySelectItem[]) => {
                handleChange(
                  'scoring_items',
                  items.map(i => i.value),
                );
              }}
              error={errors.scoring_items}
              categoryId={data.category}
              enableDragNDrop
              showPositionButtons={true}
            />
          </div>

          <div className='w-4/12'>
            {isUnsavedChanges && (
              <div className='mb-6'>
                <MessageBlock message={t('errorMessages.unsavedChanges')} type='warningBlock' />
                <hr className='w-full h-px bg-black-divider border-0 mb-6'></hr>
              </div>
            )}

            <div className='font-bold text-lg text-black mb-4'>{t('inputLabels.certifiedCoaches')}</div>
            <div className='text-base mb-6'>{t('cases.descriptionOfList')}</div>

            <EntitySelect
              entity='USER'
              values={data && data.certified_coaches}
              handleChange={(items: IEntitySelectItem[]) =>
                handleChange(
                  'certified_coaches',
                  items.map(i => i.value),
                )
              }
              error={errors.certified_coaches}
            />
          </div>
        </div>
      </form>
      {unpublish && (
        <AbstractModal
          label={t('modals.unpublishCase')}
          leftBtn={{
            label: t('buttons.cancel'),
            onClick: () => {
              handleUnpublish();
            },
          }}
          rightBtn={{
            label: t('buttons.unpublish'),
            icon: 'bi-check2-circle',
            onClick: () => {
              handleSubmit(CASE_STATE.Draft);
              handleUnpublish();
            },
          }}
          toDelete
        />
      )}
    </div>
  );
};

export default CreateCaseForm;
