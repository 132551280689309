import React from 'react';
// Version: January 17th 2022

const TermsAndConditions: React.FC = () => {
  return (
    <>
      <div className='flex justify-center'>
        <div className='text-2xl font-bold my-12'>General Terms & Data Privacy</div>
      </div>

      <div className='flex justify-center'>
        <div className='w-2/3'>
          <h1>WHAT IS SOCOCO?</h1>
          <p>
            Social and Communication Competence (SoCoCo) is a cloud-based system that facilitates remote audio/visual
            encounters between trainees and "Standardized Clients” (e.g. standardized patients, customers, employees)
            for the deliberate practice and assessment of interpersonal skills.
          </p>
          <p>
            SoCoCo integrates learning, practice, assessment, and assignments into an enhanced eLearning cycle that you
            may explore by clicking the illustrations on the right:
          </p>

          <h1 className='mt-12'>SOCOCO FEATURES</h1>
          <ul className='list-disc pl-12'>
            <li>
              <strong>Remote encounters with Standardized Clients (Coaches).</strong> Standardised Clients are actors
              who present someone with whom communication proves difficult. Users are presented with the task to e.g.
              break bad news to a patient, perform a counselling session with someone to stop smoking; interact with a
              complaining customer; or give feedback to an employee who performs sub-standard.
            </li>
            <li>
              <strong>Objective scoring:</strong> objective, structured, reproducible scoring of elements of
              professional communication. The type of problem situation determines, which elements define good
              professional communication..
            </li>
            <li>
              <strong>Structured feedback:</strong> Using pre-defined feedback items, Standardized Clients give
              structured, personalized, high-quality feedback on the performance during the encounter.
            </li>
            <li>
              <strong>Enhanced feed-back:</strong> audio/visual enhanced feedback: during the feed-back session, the
              Standardized Patient can play back recordings of what the trainee was doing at the times when the scoring
              was done. In addition, the Standardized Patient can play back prepared video-vignettes to illustrate best
              practice.
            </li>
            <li>
              <strong>Complete recording:</strong> the trainee, the Standardized Patient, and the administration are
              provided with access to a complete recording of the encounter and the feed-back session. This recording
              features a timeline that allows to jump directly to the times when the scoring was done (during encounter)
              respectively the scoring items were discussed (during feed-back).
            </li>
            <li>
              <strong>Personalized learning assignments:</strong> the system sends personalized learning assignments to
              the trainee.
            </li>
            <li>
              <strong>User account:</strong> trainees have a user account page from where they can schedule new SoCoCos
              (if scheduling is not done by the administration), and where they can review all their past SoCoCo scores
              and recordings.
            </li>
            <li>
              <strong>Administration:</strong> the administration portal of a SoCoCo-Institution allows the easy set-up
              of trainees, SPs, cases, and scoring lists. It also allows to schedule future encounters and review past
              encounters. Past encounters can be re-scored. Statistics on user performance per case and user surveys are
              available.
            </li>
            <li>
              Standardized Patients: the system allows for the on-line training of Standardized Patients up to providing
              them with certification to host SoCoCo cases.
            </li>
          </ul>

          <h1 className='mt-12'>USER TYPES AND DATA COLLECTED</h1>
          <h2>TRAINEE</h2>
          <ul className='list-disc pl-12'>
            <li>
              <strong>User data:</strong> Institution to which the user belongs, first name, last name, time-zone (for
              scheduling purposes), encrypted password.
            </li>
            <li>
              <strong>Session data:</strong> Identity of Session, ID of coach with whom the encounter was performed,
              Session date and time, Computer System and Browser used, recording of encounter (if recorded), recording
              of feed-back (if recorded), trainee’s scoring and comments on the case, trainee’s scoring and comments on
              the coach’s performance, trainee's general comments.
            </li>
          </ul>
          <h2>Standardised Clients (COACHES)</h2>
          <ul className='list-disc pl-12'>
            <li>
              <strong>User data:</strong> first name, last name, gender (to allow for using the correct case
              description), time-zone (for scheduling purposes), encrypted password.
            </li>
            <li>
              <strong>Session data:</strong> Identity of Session, ID of trainee(s) with whom the encounter was
              performed, Session date and time, Computer System and Browser used, recording of encounter (if recorded),
              recording of feed-back (if recorded).
            </li>
          </ul>
          <h2>INSTITUTION ADMINISTRATOR</h2>
          <ul className='list-disc pl-12'>
            <li>
              <strong>User data:</strong> first name, last name, encrypted password
            </li>
          </ul>

          <h1 className='mt-12'>DATA SECURITY & STORAGE</h1>
          <ul className='list-disc pl-12'>
            <li>
              Data is stored encrypted at rest, in a non-relational MongoDB cloud database, served through
              https://cloud.mongodb.com/ (MongoDb Privacy: https://www.mongodb.com/legal/privacy-policy, MongoDb
              Security: https://www.mongodb.com/security).
            </li>
            <li>
              All services, databases and files are stored on AWS (Amazon Web Services) Servers in the Frankfurt Region
              (AWS Privacy: https://aws.amazon.com/de/privacy/, AWS Security: https://aws.amazon.com/de/security/)
            </li>
            <li>
              Video data (recordings, streams) are processed via DailyCo 3rd party service (Privacy:
                https://www.daily.co/legal/privacy/, DailyCo Security: https://www.daily.co/products/security-at-daily/)
            </li>
          </ul>

          <h1 className='mt-12'>DATA RETENTION AND DELETION</h1>
          <p>
            Data collected through SoCoCo services is kept until deletion is requested. To issue a data report or
            deletion request, please send an email to <a href='mailto:support@sococo.ch'>support@sococo.ch</a>.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
