import React, { ReactNode, Ref, useEffect, useMemo, useRef, useState } from 'react';
import {
  ScoreResultDto,
  SessionRecordDownloadResponseDto,
  SessionRecordResponseDto,
  TeachableMomentDto,
} from '../../service/dto/session.dto';
import { RecordStatus } from '../../common/enums';
import Timeline from '../Timeline';
import { useTranslation } from 'react-i18next';

export interface IRecordPlayerManipulationInfo {
  isPlaying: boolean;
  isMuted: boolean;
  timeInSeconds: number;
}

interface IRecordPlayerProps {
  record: SessionRecordResponseDto;
  isReadonly: boolean;
  onManipulationCallback?: (m: IRecordPlayerManipulationInfo) => unknown;
  watchManipulationInfo?: IRecordPlayerManipulationInfo;
  scoreResult?: ScoreResultDto;
  teachableMoments?: TeachableMomentDto[];
  download?: SessionRecordDownloadResponseDto;
  error?: string;
  isModal?: boolean;
}

interface IRecordPlayerState {
  isMuted: boolean;
}

const defaultRecordPlayerModalState: IRecordPlayerState = {
  isMuted: true,
};

const RecordPlayer = ({
  scoreResult,
  teachableMoments,
  record,
  download,
  error,
  isReadonly,
  watchManipulationInfo,
  onManipulationCallback,
  isModal,
}: IRecordPlayerProps) => {
  const { t } = useTranslation();
  const [componentState, _setComponentState] = useState({
    ...defaultRecordPlayerModalState,
  });
  const componentStateRef = useRef(componentState);
  const setComponentState = (data: IRecordPlayerState) => {
    componentStateRef.current = data;
    _setComponentState(data);
  };
  const videoPlayerRef: Ref<HTMLVideoElement> = useRef(null);
  const [watchTime, setWatchTime] = useState<number | undefined>(undefined);
  const [isPaused, setIsPaused] = useState<boolean>(true);

  useEffect(() => {
    if (!watchManipulationInfo || !videoPlayerRef.current) {
      return;
    }

    setWatchTime(watchManipulationInfo.timeInSeconds);
    setComponentState({
      ...componentState,
      isMuted: watchManipulationInfo.isMuted,
    });
    if (watchManipulationInfo.isPlaying) {
      videoPlayerRef.current.play();
    } else {
      videoPlayerRef.current.pause();
    }
  }, [watchManipulationInfo]);

  const onVideoTouch = () => {
    if (!videoPlayerRef?.current || isReadonly) {
      return;
    }
    setIsPaused(!videoPlayerRef.current.paused);
    videoPlayerRef.current.paused ? videoPlayerRef.current.play() : videoPlayerRef.current.pause();
    onManipulationCallback &&
      onManipulationCallback({
        isMuted: videoPlayerRef.current.muted,
        isPlaying: !videoPlayerRef.current.paused,
        timeInSeconds: videoPlayerRef.current.currentTime,
      });
  };

  const wrapTextContent = (s: string, isError: boolean = false): ReactNode[] => [
    <div className={`flex flex-col justify-center h-full italic ${isError ? 'text-negative' : 'text-primary'}`}>
      <div className='text-center'>{s}</div>
    </div>,
  ];

  const renderPlayButton = () => {
    return (
      <div
        onClick={onVideoTouch}
        className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-positive w-8.5 h-8.5 rounded-full cursor-pointer flex items-center justify-center'
      >
        <i className={'bi bi-play-fill text-xl text-white'} />
      </div>
    );
  };
  const isPlayButtonVisible = useMemo(() => {
    return videoPlayerRef.current?.paused ?? true;
  }, [videoPlayerRef.current, videoPlayerRef.current?.paused]);

  const getContent = (): ReactNode[] => {
    switch (record.status) {
      case RecordStatus.Null:
        return wrapTextContent(t('record_modal.status.null'));
      case RecordStatus.Aborted:
        return wrapTextContent(t('record_modal.status.aborted'));
      case RecordStatus.Enqueued:
      case RecordStatus.Progress:
      case RecordStatus.Started:
        return wrapTextContent(
          t('record_modal.status.processing', {
            progress: record.percentageProcessed ?? 0,
          }),
        );
      case RecordStatus.Available:
        if (!download) {
          return wrapTextContent(t('record_modal.status.download'));
        }

        return [
          <div className='relative w-full h-full'>
            <video
              key='video'
              ref={videoPlayerRef}
              muted={componentState.isMuted}
              onClick={onVideoTouch}
              onTouchStart={onVideoTouch}
              playsInline={true}
              preload='auto'
              className='h-100 w-100'
              style={{ overflow: 'hidden', borderRadius: '12px' }}
            >
              <source src={download.url} type='video/mp4' />
            </video>
            {isPlayButtonVisible && renderPlayButton()}
            <div className='absolute left-0 bottom-0'>
              <div
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  const newMutedState = !componentState.isMuted;
                  setComponentState({
                    ...componentState,
                    isMuted: newMutedState,
                  });
                  onManipulationCallback &&
                    onManipulationCallback({
                      isMuted: newMutedState,
                      isPlaying: !videoPlayerRef.current!.paused,
                      timeInSeconds: videoPlayerRef.current!.currentTime,
                    });
                }}
                className={`py-1.5 px-2 m-2 w-8 h-8 rounded-lg flex items-center cursor-pointer border border-solid ${
                  componentState.isMuted
                    ? 'text-negative border-negative bg-white/75'
                    : 'text-white bg-primary-dark border-primary-dark'
                } `}
              >
                <i className={`bi bi-${componentState.isMuted ? 'mic-mute' : 'mic'}`} />
              </div>
            </div>
          </div>,
          <div key='timeline' className={`py-3 ${isModal && 'px-4'}`}>
            <Timeline
              videoRef={videoPlayerRef}
              scoreResult={scoreResult ?? {}}
              teachableMoments={teachableMoments ?? []}
              startTime={record.recordStartTime!}
              timeSequences={record.recordingSequences}
              videoTime={record.durationInSeconds!}
              watchTimeUpdate={watchTime}
              isReadonly={isReadonly}
              timeUpdateCallback={(newTime: number) => {
                if (videoPlayerRef.current) {
                  onManipulationCallback &&
                    onManipulationCallback({
                      isMuted: videoPlayerRef.current.muted,
                      isPlaying: !videoPlayerRef.current.paused,
                      timeInSeconds: newTime,
                    });
                }
              }}
            />
          </div>,
        ];
      default:
        return wrapTextContent('Not expected status');
    }
  };
  const content = error ? [wrapTextContent(error, true)] : getContent();
  return (
    <>
      {content[0] && (
        <div className={isModal ? 'bg-white overflow-hidden border border-solid border-gray-light rounded-b-lg' : ''}>
          {content[0]}
        </div>
      )}
      {content.length > 1 && (
        <div className={isModal ? 'h-19 bg-white border border-solid border-gray-light rounded-lg mt-2' : ''}>
          {content[1]}
        </div>
      )}
    </>
  );
};

export default RecordPlayer;
