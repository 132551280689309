import thunk from 'redux-thunk';
import {
  AuthState,
  CaseCategoriesSelectorState,
  CaseEvaluationState,
  CaseSearchState,
  CategorySearchState,
  DeviceState,
  EvaluationCoachSearchState,
  EvaluationTraineeSearchState,
  HistoryCaseSelectorState,
  HistoryCategorySelectorState,
  InsightsCasesFilterState,
  InsightsEvaluationFilterState,
  InsightsUsageFilterState,
  InstitutionSearchState,
  InstitutionState,
  LiveSessionCaseSelectorState,
  LiveSessionCategorySelectorState,
  OnlyMineState,
  OnlyScheduledState,
  ReportingSearchState,
  ScheduleCasesFilterState,
  ScheduleCategoriesFilterState,
  ScoringItemCategoriesSelectorState,
  ScoringItemSearchState,
  SessionConfigState,
  SessionEvaluationState,
  SessionLogCaseSelectorState,
  SessionLogCategorySelectorState,
  SessionLogCoachFilterState,
  SessionLogTraineeFilterState,
  SessionResultState,
  SessionTimePlayingState,
  SocketComponentState,
  SocketFeedbackState,
  SocketManipulationState,
  SocketRecordState,
  SocketSessionState,
  UserDateRegistrationFilterState,
  UsersRoleFilterState,
  UsersSearchFilterState,
  UsersSortState,
  VideoTitleConfigState,
} from './types';

import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { rootReducer } from './reducer';

export interface RootState {
  authState: AuthState;
  institutionState: InstitutionState;
  videoTitleConfigState: VideoTitleConfigState;
  sessionConfigState: SessionConfigState;
  sessionEvaluationState: SessionEvaluationState;
  deviceState: DeviceState;
  socketSessionState: SocketSessionState;
  socketComponentState: SocketComponentState;
  socketRecordState: SocketRecordState;
  socketFeedbackState: SocketFeedbackState;
  socketManipulationState: SocketManipulationState;
  sessionResultState: SessionResultState;
  sessionTimePlayingState: SessionTimePlayingState;
  insightsUsageFilterState: InsightsUsageFilterState;
  insightsCasesFilterState: InsightsCasesFilterState;
  insightsEvaluationFilterState: InsightsEvaluationFilterState;
  insightsReportingFilterState: InsightsEvaluationFilterState;
  userDateRegistrationFilterState: UserDateRegistrationFilterState;
  usersSearchFilterState: UsersSearchFilterState;
  usersRoleFilterState: UsersRoleFilterState;
  usersSortState: UsersSortState;
  scoringItemSearchState: ScoringItemSearchState;
  scoringItemCategoriesSelectorState: ScoringItemCategoriesSelectorState;
  caseSearchState: CaseSearchState;
  caseCategoriesSelectorState: CaseCategoriesSelectorState;
  categorySearchState: CategorySearchState;
  scheduleCasesFilterState: ScheduleCasesFilterState;
  scheduleCategoriesFilterState: ScheduleCategoriesFilterState;
  onlyScheduledState: OnlyScheduledState;
  onlyMineState: OnlyMineState;
  liveSessionCaseSelectorState: LiveSessionCaseSelectorState;
  liveSessionCategorySelectorState: LiveSessionCategorySelectorState;
  historyCaseSelectorState: HistoryCaseSelectorState;
  historyCategorySelectorState: HistoryCategorySelectorState;
  sessionLogCaseSelectorState: SessionLogCaseSelectorState;
  sessionLogCategorySelectorState: SessionLogCategorySelectorState;
  sessionLogCoachFilterState: SessionLogCoachFilterState;
  sessionLogTraineeFilterState: SessionLogTraineeFilterState;
  institutionSearchState: InstitutionSearchState;
  caseEvaluationState: CaseEvaluationState;
  evaluationTraineeSearchState: EvaluationTraineeSearchState;
  evaluationCoachSearchState: EvaluationCoachSearchState;
  reportingSearchState: ReportingSearchState;
}
// @ts-ignore
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = configureStore({
//   reducer: rootReducer,
//   middleware: getDefaultMiddleware =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }),
//   enhancers: [applyMiddleware(thunk)],
//   // enhancers: [composeEnhancers(applyMiddleware(thunk))],
// });

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  devTools: (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
});

export type AppDispatchType = typeof store.dispatch;
export const useAppDispatch: () => AppDispatchType = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
