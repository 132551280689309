import React, { useState } from 'react';

interface ISwitchButtonProps {
  option: { id: string; title: string; status: boolean };
  handleOption: (option: { id: string; title: string; status: boolean }) => void;
}

const SwitchButton = ({ option, handleOption }: ISwitchButtonProps) => {
  const [status, setStatus] = useState(option.status);
  const handleStatus = () => {
    setStatus(!status);
    handleOption({ id: option.id, title: option.title, status: status });
  };
  return (
    <div className='flex items-center'>
        <label className='relative inline-block w-8 h-4'>
          <input
            type='checkbox'
            id={option.id}
            className='opacity-0' 
            onChange={() => handleStatus()}
            checked={status}
          />
          <span className='slider duration-300 round rounded-3xl absolute inset-0 cursor-pointer bg-gray'></span>
        </label>
      <div className='ml-2'>{option.title}</div>
    </div>
  );
};

export default SwitchButton;
