import axios from 'axios';
import React from 'react';
import { createRoot } from 'react-dom/client';
import Alert from '../src/utils/alert';
import App from './App';
// REDUX
import { Provider } from 'react-redux';
import { signout } from './redux/actions';
import store from './redux/store';

import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/css/style.css';
import './i18n/config';

// Important -> Catch server errors.
axios.interceptors.response.use(
  response => response,
  error => {
    if (401 === error?.response?.status) {
      store.dispatch<any>(signout());
      Alert.warning('Sorry your session has been expired. Please signin again in a few seconds.');
      const redirectTo = window.location.hash.substring(1);
      const currentSearchString = window.location.search;
      const currentPath = window.location.pathname;
      if (!currentPath.includes('#/signin') && (!currentSearchString || !currentSearchString.includes('redirectTo'))) {
        window.location.href = `#/signin?redirectTo=${redirectTo}`;
      }
      return Promise.reject(error);
    } 
    return Promise.reject(error);
  },
);

const container = document.getElementById('root');
const root = createRoot(container as Element);

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
