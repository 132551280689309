import React from 'react';
import { useTranslation } from 'react-i18next';

// SERVICES
import { SessionResponseDto } from '../../service/dto/session.dto';
import { CaseResponseDto } from '../../service/dto/case.dto';

// UI COMPONENTS
import InputLabel from '../../components/InputLabel';

// UTILS
import { getDateAndTimeDuration, toLocaleDateTimeString } from '../../utils';
import { SessionType } from '../../common/enums';
import { start } from 'repl';

interface IBookSessionContentProps {
  session: SessionResponseDto;
  caseData?: CaseResponseDto;
}

const BookSessionContent = ({ session, caseData }: IBookSessionContentProps) => {
  const { t } = useTranslation();

  const renderTime = () => {
    return `⏱️ ${toLocaleDateTimeString(new Date(session.startDate))}`;
  };

  const renderLocation = (location?: string) => {
    console.log(location);
    const isOffline = !(location === '' || location === undefined);

    return (
      <p className='text-lg mb-2'>
        <span className={`text-lg mb-6 text-${isOffline ? 'negative-darker' : 'black'}`}>
          {isOffline ? `📍 ${location?.toUpperCase()}` : '🌐 ONLINE'}
        </span>{' '}
        {isOffline && <span className='text-gray'>{t('session.location.offline')}</span>}
      </p>
    );
  };

  return (
    <div className='grid grid-cols-1'>
      <div className='grid grid-cols-3'>
        {session.type === SessionType.RegularSession && (
          <div className='col-span-3 mb-6'>
            <InputLabel label={t('inputLabels.locationAndTime')} />
            {renderLocation(session.location)}
            <p className='font-bold text-black'>{renderTime()}</p>
          </div>
        )}

        <div className='col-span-1'>
          <InputLabel label={t('inputLabels.sessionCoach')} />
          <p className='text-black mb-6'>👨‍🏫 {session.coach.firstName + ' ' + session.coach.lastName}</p>
        </div>
        <div className='col-span-1'>
          <InputLabel label={t('inputLabels.sessionLength')} />
          <p className='text-black mb-6'>
            {t('inputLabels.interview')} {session.caseTemplateSummary.interview_duration} {t('table.min')}. <br />
            {t('inputLabels.feedback')} {session.caseTemplateSummary.feedback_duration} {t('table.min')}.
          </p>
        </div>
      </div>
      <div>
        <div className='col-span-3'>
          <InputLabel label={t('inputLabels.description')} />
          <p className='text-black'>{caseData?.description}</p>
        </div>
      </div>
    </div>
  );
};

export default BookSessionContent;
