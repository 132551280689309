import { combineReducers } from 'redux';

import { COMPONENT_STATE, RecordStatus, SessionStatus, SessionType } from '../common/enums';
import {
  AuthAction,
  AuthState,
  CaseCategoriesSelectorAction,
  CaseCategoriesSelectorState,
  CaseEvaluationAction,
  CaseEvaluationState,
  CaseSearchAction,
  CaseSearchState,
  CategorySearchAction,
  CategorySearchState,
  DevicesAction,
  DeviceState,
  EvaluationCoachSearchAction,
  EvaluationCoachSearchState,
  EvaluationTraineeSearchAction,
  EvaluationTraineeSearchState,
  HistoryCaseSelectorAction,
  HistoryCaseSelectorState,
  HistoryCategorySelectorAction,
  HistoryCategorySelectorState,
  InsightsCasesFilterAction,
  InsightsCasesFilterState,
  InsightsEvaluationFilterAction,
  InsightsEvaluationFilterState,
  InsightsReportingFilterAction,
  InsightsReportingFilterState,
  InsightsUsageFilterAction,
  InsightsUsageFilterState,
  InstitutionAction,
  InstitutionSearchAction,
  InstitutionSearchState,
  InstitutionState,
  LiveSessionCaseSelectorAction,
  LiveSessionCaseSelectorState,
  LiveSessionCategorySelectorAction,
  LiveSessionCategorySelectorState,
  OnlyMineAction,
  OnlyMineState,
  OnlyScheduledAction,
  OnlyScheduledState,
  ReportingSearchAction,
  ReportingSearchState,
  ScheduleCasesFilterAction,
  ScheduleCasesFilterState,
  ScheduleCategoriesFilterAction,
  ScheduleCategoriesFilterState,
  ScoringItemCategoriesSelectorAction,
  ScoringItemCategoriesSelectorState,
  ScoringItemSearchAction,
  ScoringItemSearchState,
  SessionConfigAction,
  SessionConfigState,
  SessionEvaluationAction,
  SessionEvaluationState,
  SessionLogCaseSelectorAction,
  SessionLogCaseSelectorState,
  SessionLogCategorySelectorAction,
  SessionLogCategorySelectorState,
  SessionLogCoachFilterAction,
  SessionLogCoachFilterState,
  SessionLogTraineeFilterAction,
  SessionLogTraineeFilterState,
  SessionResultAction,
  SessionResultState,
  SessionScoreResultState,
  SessionSetScoreResultAction,
  SessionTimePlayingAction,
  SessionTimePlayingState,
  SetSessionScoreValueAction,
  SetSessionStatusAction,
  SetSessionTeachableMomentsAction,
  SetSessionTotalScoreAction,
  SocketComponentAction,
  SocketComponentState,
  SocketFeedbackAction,
  SocketFeedbackState,
  SocketManipulationAction,
  SocketManipulationState,
  SocketRecordAction,
  SocketRecordState,
  SocketSessionAction,
  SocketSessionState,
  UserDateRegistrationFilterAction,
  UserDateRegistrationFilterState,
  UsersRoleFilterAction,
  UsersRoleFilterState,
  UsersSearchFilterAction,
  UsersSearchFilterState,
  UsersSortAction,
  UsersSortState,
  VideoTitleConfigAction,
  VideoTitleConfigState,
} from './types';

import {
  SessionEvaluationCaseType,
  SessionEvaluationTechnicalType,
  SessionEvaluationType,
} from '../service/dto/session-evaluate.dto';
import { ScoreResultDto, TeachableMomentDto } from '../service/dto/session.dto';
import { subtractDays } from '../utils/date';
import { actionTypes } from './constants';

// INITIAL STATES
const currentUser = localStorage.getItem('user') ? JSON.parse((localStorage as Storage).getItem('user') || '') : null;

const currentInstitution = localStorage.getItem('institution')
  ? JSON.parse((localStorage as Storage).getItem('institution') || '')
  : null;

const initialAuthState: AuthState = currentUser
  ? { isLoggedIn: true, user: currentUser }
  : { isLoggedIn: false, user: null };

const initialInstitutionState: InstitutionState = currentInstitution
  ? currentInstitution
  : {
      id: '',
      code: '',
      name: '',
    };

const initialVideoTitleConfigState: VideoTitleConfigState = { name: '', value: null, index: null };

const initialSessionConfigState: SessionConfigState = {
  isLoaded: false,
  start: false,
  session: {
    id: '',
    categoryName: '',
    shareToken: '',
    status: SessionStatus.Open,
    type: SessionType.RegularSession,
    startDate: '',
    coach: { id: '', firstName: '', lastName: '' },
    caseTemplateSummary: {
      id: '',
      name: '',
      description: '',
      interview_duration: 0,
      feedback_duration: 0,
      allowTeachableMoments: false,
    },
    trainee: { id: '', firstName: '', lastName: '' },
    scoreResult: {},
    totalScore: 0,
    coachingSessionStart: '',
    coachingSessionRecord: {
      allowRecording: false,
      roomSid: '',
      status: RecordStatus.Null,
      updatedAt: new Date(),
      compositionSid: '',
      compositionUrl: '',
      mediaUri: '',
      recordStartTime: '',
      percentageProcessed: 0,
      remainingTimeInSeconds: 0,
      recordingSequences: [],
      size: 0,
      durationInSeconds: 0,
      failedReason: '',
      recordingActive: false,
      recordType: 'coaching',
    },
    dailyCoachingSessionRecord: {
      allowRecording: false,
      roomSid: '',
      status: RecordStatus.Null,
      updatedAt: new Date(),
      compositionSid: '',
      compositionUrl: '',
      mediaUri: '',
      recordStartTime: '',
      percentageProcessed: 0,
      remainingTimeInSeconds: 0,
      recordingSequences: [],
      size: 0,
      durationInSeconds: 0,
      failedReason: '',
      recordingActive: false,
      recordType: 'coaching',
    },
    feedbackSessionRecord: {
      allowRecording: false,
      roomSid: '',
      status: RecordStatus.Null,
      updatedAt: new Date(),
      compositionSid: '',
      compositionUrl: '',
      mediaUri: '',
      recordStartTime: '',
      percentageProcessed: 0,
      remainingTimeInSeconds: 0,
      recordingSequences: [],
      size: 0,
      durationInSeconds: 0,
      failedReason: '',
      recordingActive: false,
      recordType: 'feedback',
    },
    dailyFeedbackSessionRecord: {
      allowRecording: false,
      roomSid: '',
      status: RecordStatus.Null,
      updatedAt: new Date(),
      compositionSid: '',
      compositionUrl: '',
      mediaUri: '',
      recordStartTime: '',
      percentageProcessed: 0,
      remainingTimeInSeconds: 0,
      recordingSequences: [],
      size: 0,
      durationInSeconds: 0,
      failedReason: '',
      recordingActive: false,
      recordType: 'feedback',
    },
    dailyRoomUrl: '',
  },
};

export const initialDevicesConfigState: DeviceState = {
  devices: [
    {
      label: '',
      deviceId: '',
      kind: 'audioinput',
      active: false,
      groupId: '',
      enabled: true,
      isMobile: false,
    },
  ],
};

const initialSocketComponentState: SocketComponentState = {
  componentState: COMPONENT_STATE.WAITING,
};

const initialSocketSessionState: SocketSessionState = {
  sessionStatus: SessionStatus.Coaching,
};

const initialSocketRecordState: SocketRecordState = {
  sessionRecord: {
    allowRecording: false,
    roomSid: '',
    status: RecordStatus.Null,
    recordingSequences: [],
    updatedAt: new Date(),
    recordingActive: false,
    recordType: 'coaching',
  },
};

const initialSocketManipulationState: SocketManipulationState = {
  sessionManipulation: {
    sessionId: '',
    isVisible: false,
    isPlaying: false,
    isMuted: true,
    timeInSeconds: 0,
  },
};

const initialSocketFeedbackState: SocketFeedbackState = {
  sessionStatus: SessionStatus.Feedback,
  startFeedbackPayload: {
    sessionId: '',
    room: {
      sid: '',
      uniqueName: '',
    },
  },
};

const initialSessionResultState: SessionResultState = {
  sessionRecord: {
    coachingSession: {},
  },
};

export const initialSessionEvaluationState: SessionEvaluationState = {
  sessionEvaluation: {
    type: SessionEvaluationType.TraineeEvaluation,
    caseTemplate: '',
    session: '',
    comment: '',
    technical: [
      { type: SessionEvaluationTechnicalType.TechnicalEvaluationVideo, value: 10 },
      { type: SessionEvaluationTechnicalType.TechnicalEvaluationAudio, value: 10 },
    ],
    coachOverall: 10,
    coachComment: '',
    traineeOverall: 10,
    traineeComment: '',
    caseComment: '',
    generalComment: '',
    caseEvaluation: [
      { type: SessionEvaluationCaseType.CaseEvaluationScoringItems, value: 10 },
      { type: SessionEvaluationCaseType.CaseEvaluationMaterial, value: 10 },
      { type: SessionEvaluationCaseType.CaseEvaluationOverall, value: 10 },
    ],
  },
};

const initialSessionResultValueState: SessionScoreResultState = {};

const initialSessionTimePlayingState: SessionTimePlayingState = {};

// Filters
const initialInsightsUsageFilterState: InsightsUsageFilterState = {
  from: subtractDays(new Date(), 30),
  until: new Date(),
};

const initialInsightsCasesFilterState: InsightsCasesFilterState = {
  from: subtractDays(new Date(), 30),
  until: new Date(),
};

const initialInsightsEvaluationFilterState: InsightsEvaluationFilterState = {
  from: subtractDays(new Date(), 90),
  until: new Date(),
};

const initialInsightsReportingFilterState: InsightsReportingFilterState = {
  from: subtractDays(new Date(), 30),
  until: new Date(),
};

//Filter
const initialUserDateRegistrationFilterState: UserDateRegistrationFilterState = {};

const initialUsersSearchFilterState: UsersSearchFilterState = {
  search: '',
};

const initialUsersRoleFilterState: UsersRoleFilterState = {
  roleFilter: [],
};

const initialUserSortState: UsersSortState = {};

const initialScoringItemSearchState: ScoringItemSearchState = {
  search: '',
};

const initialScoringItemCategoriesSelectorState: ScoringItemCategoriesSelectorState = {
  selectedCategories: [],
};

const initialCaseSearchState: CaseSearchState = {
  search: '',
};

const initialCaseCategoriesSelectorState: CaseCategoriesSelectorState = {
  selectedCategories: [],
};

const initialCategorySearchState: CategorySearchState = {
  searchCategory: '',
};

const initialScheduleCasesFilterState: ScheduleCasesFilterState = {
  selectedCases: [],
};

const initialScheduleCategoriesFilterState: ScheduleCategoriesFilterState = {
  selectedCategories: [],
};

const initialOnlyScheduledState: OnlyScheduledState = {
  onlyScheduled: false,
};

const initialOnlyMineState: OnlyMineState = {
  onlyMine: false,
};

const initialLiveSessionCaseSelectorState: LiveSessionCaseSelectorState = {
  selectedCases: [],
};

const initialLiveSessionCategorySelectorState: LiveSessionCategorySelectorState = {
  selectedCategories: [],
};

const initialHistoryCaseSelectorState: HistoryCaseSelectorState = {
  selectedCases: [],
};

const initialHistoryCategorySelectorState: HistoryCategorySelectorState = {
  selectedCategories: [],
};

const initialSessionLogCaseSelectorState: SessionLogCaseSelectorState = {
  selectedCases: [],
};

const initialSessionLogCategorySelectorState: SessionLogCategorySelectorState = {
  selectedCategories: [],
};

const initialSessionLogCoachFilterState: SessionLogCoachFilterState = {
  coachFilter: '',
};

const initialSessionLogTraineeFilterState: SessionLogTraineeFilterState = {
  traineeFilter: '',
};

const initialInstitutionSearchState: InstitutionSearchState = {
  search: '',
};

const initialEvaluationCoachSearchState: EvaluationCoachSearchState = {
  coachSearch: '',
};

const initialEvaluationTraineeSearchState: EvaluationTraineeSearchState = {
  traineeSearch: '',
};

const initialCaseEvaluationState: CaseEvaluationState = {};

const initialReportingSearchState: ReportingSearchState = {
  search: '',
};

// REDUCERS
const auth = (state: AuthState = initialAuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case actionTypes.SIGNIN:
      localStorage.setItem('user', JSON.stringify(action.payload));
      return { ...state, isLoggedIn: true, user: action.payload };

    case actionTypes.SIGNUP:
      return { ...state, isLoggedIn: false };

    case actionTypes.SIGNOUT:
      localStorage.removeItem('user');
      localStorage.removeItem('institution');

      return { ...state, isLoggedIn: false, user: null };

    case actionTypes.UPDATE_ME:
      return { ...state, isLoggedIn: true, user: action.payload };

    default:
      return state;
  }
};

const institution = (
  state: InstitutionState = initialInstitutionState,
  action: InstitutionAction,
): InstitutionState => {
  switch (action.type) {
    case actionTypes.TOGGLE_INSTITUTION:
      localStorage.setItem('institution', JSON.stringify(action.payload));

      return { ...state, ...action.payload };

    default:
      return state;
  }
};

const videoTitleConfig = (
  state = initialVideoTitleConfigState,
  action: VideoTitleConfigAction,
): VideoTitleConfigState => {
  switch (action.type) {
    case actionTypes.SET_VIDEO_TITLE_CONFIG:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

type AnySessionConfigAction =
  | SessionConfigAction
  | SetSessionStatusAction
  | SetSessionTeachableMomentsAction
  | SocketRecordAction
  | SessionSetScoreResultAction
  | SocketSessionAction
  | SetSessionScoreValueAction
  | SetSessionTotalScoreAction;
const sessionConfig = (
  state: SessionConfigState = initialSessionConfigState,
  action: AnySessionConfigAction,
): SessionConfigState => {
  switch (action.type) {
    case actionTypes.SET_SESSION_CONFIG:
      return { ...state, ...(action.payload as SessionConfigState), isLoaded: true };

    case actionTypes.SET_SESSION_STATUS:
      return {
        ...state,
        session: {
          ...state.session,
          status: action.payload as SessionStatus,
        },
      };

    case actionTypes.SET_SESSION_TEACHABLE_MOMENTS:
      return {
        ...state,
        session: {
          ...state.session,
          teachableMoments: action.payload as TeachableMomentDto[],
        },
      };

    case actionTypes.RECORD_STATUS_UPDATE:
      const sessionRecord = (action.payload as SocketRecordState).sessionRecord;
      switch (sessionRecord.recordType) {
        case 'coaching':
          return {
            ...state,
            session: {
              ...state.session,
              dailyCoachingSessionRecord: sessionRecord,
            },
          };
        case 'feedback':
          return {
            ...state,
            session: {
              ...state.session,
              dailyFeedbackSessionRecord: sessionRecord,
            },
          };
        default:
          return state;
      }

    case actionTypes.END_SESSION:
      return {
        ...state,
        session: {
          ...state.session,
          status: SessionStatus.Finished,
        },
      };

    case actionTypes.SESSION_SCORE_VALUE:
      const payload = (action as SetSessionScoreValueAction).payload;
      if (payload.sessionId !== state.session.id) {
        return state;
      }

      const updatedScoreResult = { ...state.session.scoreResult };
      for (const key in payload.score) {
        if (updatedScoreResult[key]) {
          updatedScoreResult[key].value = payload.score[key].value;
          const updatedTmstmp =
            typeof payload.score[key].tmstmp === 'string'
              ? payload.score[key].tmstmp
              : new Date(payload.score[key].tmstmp).toISOString();
          updatedScoreResult[key].tmstmp = updatedTmstmp;
        }
      }

      return {
        ...state,
        session: {
          ...state.session,
          scoreResult: updatedScoreResult,
        },
      };

    case actionTypes.SESSION_END:
      const sessionEndPayload = (action as SocketSessionAction).payload;
      return {
        ...state,
        session: {
          ...state.session,
          status: SessionStatus.Finished,
        },
      };

    case actionTypes.SET_SESSION_TOTAL_SCORE:
      console.log('SET_SESSION_TOTAL_SCORE', action.payload);
      return {
        ...state,
        session: {
          ...state.session,
          totalScore: action.payload as number,
        },
      };

    default:
      return state;
  }
};

const devicesConfig = (state: DeviceState = initialDevicesConfigState, action: DevicesAction) => {
  switch (action.type) {
    case actionTypes.SET_DEVICES:
      return { ...state, ...action.payload };
  }
  return state;
};

const socketComponentConfig = (
  state: SocketComponentState = initialSocketComponentState,
  action: SocketComponentAction,
) => {
  switch (action.type) {
    case actionTypes.ROOM_STATUS:
      return { ...state, componentState: action.payload.componentState };
    case actionTypes.SESSION_END:
      return { ...initialSocketComponentState };
  }
  return state;
};

// TODO: I think this is already extra.
const socketFeedbackConfig = (
  state: SocketFeedbackState = initialSocketFeedbackState,
  action: SocketFeedbackAction,
) => {
  switch (action.type) {
    case actionTypes.START_FEEDBACK:
      return {
        ...state,
        sessionStatus: action.payload.sessionStatus,
        startFeedbackPayload: action.payload.startFeedbackPayload,
      };
    case actionTypes.SESSION_END:
      return { ...initialSocketFeedbackState };
  }
  return state;
};

// const socketSessionConfig = (state: SocketSessionState = initialSocketSessionState, action: SocketSessionAction) => {
//   switch (action.type) {
//     case actionTypes.END_SESSION:
//       return { ...state, sessionStatus: action.payload.sessionStatus };
//     case actionTypes.SESSION_END:
//       return { ...initialSocketSessionState };
//   }
//   return state;
// };

const socketRecordConfig = (state: SocketRecordState = initialSocketRecordState, action: SocketRecordAction) => {
  switch (action.type) {
    case actionTypes.RECORD_STATUS_UPDATE:
      return { ...state, sessionRecord: action.payload.sessionRecord };
    case actionTypes.SESSION_END:
      return { ...initialSocketRecordState };
  }
  return state;
};

const socketManipulationConfig = (
  state: SocketManipulationState = initialSocketManipulationState,
  action: SocketManipulationAction,
) => {
  switch (action.type) {
    case actionTypes.SESSION_RECORD_MANIPULATION_LISTENER:
      return { ...state, sessionManipulation: action.payload.sessionManipulation };
    case actionTypes.SESSION_END:
      return { ...initialSocketManipulationState };
  }
  return state;
};

const sessionResultReducer = (state: SessionResultState = initialSessionResultState, action: SessionResultAction) => {
  switch (action.type) {
    case actionTypes.SET_SESSION_RESULT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const sessionTimePlayingReducer = (
  state: SessionTimePlayingState = initialSessionTimePlayingState,
  action: SessionTimePlayingAction,
) => {
  switch (action.type) {
    case actionTypes.SET_SESSION_TIME_PLAYING:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const sessionEvaluationReducer = (
  state: SessionEvaluationState = initialSessionEvaluationState,
  action: SessionEvaluationAction,
) => {
  switch (action.type) {
    case actionTypes.SESSION_EVALUATION_UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const insightsUsageFilterReducer = (
  state: InsightsUsageFilterState = initialInsightsUsageFilterState,
  action: InsightsUsageFilterAction,
) => {
  switch (action.type) {
    case actionTypes.SET_INSIGHTS_USAGE_FILTER_FROM_UNTIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const insightsCasesFilterReducer = (
  state: InsightsCasesFilterState = initialInsightsCasesFilterState,
  action: InsightsCasesFilterAction,
) => {
  switch (action.type) {
    case actionTypes.SET_INSIGHTS_CASES_FILTER_FROM_UNTIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const insightsEvaluationFilterReducer = (
  state: InsightsEvaluationFilterState = initialInsightsEvaluationFilterState,
  action: InsightsEvaluationFilterAction,
) => {
  switch (action.type) {
    case actionTypes.SET_INSIGHTS_EVALUATION_FILTER_FROM_UNTIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const insightsReportingFilterReducer = (
  state: InsightsReportingFilterState = initialInsightsReportingFilterState,
  action: InsightsReportingFilterAction,
) => {
  switch (action.type) {
    case actionTypes.SET_INSIGHTS_REPORTING_FILTER_FROM_UNTIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

//Filter
const userDateRegistrationFilterReducer = (
  state: UserDateRegistrationFilterState = initialUserDateRegistrationFilterState,
  action: UserDateRegistrationFilterAction,
) => {
  switch (action.type) {
    case actionTypes.SET_USER_DATE_REGISTRATION_FILTER_FROM_UNTIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const usersSearchFilterReducer = (
  state: UsersSearchFilterState = initialUsersSearchFilterState,
  action: UsersSearchFilterAction,
) => {
  switch (action.type) {
    case actionTypes.SET_USERS_SEARCH_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const usersRoleFilterReducer = (
  state: UsersRoleFilterState = initialUsersRoleFilterState,
  action: UsersRoleFilterAction,
) => {
  switch (action.type) {
    case actionTypes.SET_USERS_ROLE_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const usersSortReducer = (state: UsersSortState = initialUserSortState, action: UsersSortAction) => {
  switch (action.type) {
    case actionTypes.SET_USERS_SORT_BY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const scoringItemSearchReducer = (
  state: ScoringItemSearchState = initialScoringItemSearchState,
  action: ScoringItemSearchAction,
) => {
  switch (action.type) {
    case actionTypes.SET_SCORING_ITEM_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const scoringItemCategoriesSelectorReducer = (
  state: ScoringItemCategoriesSelectorState = initialScoringItemCategoriesSelectorState,
  action: ScoringItemCategoriesSelectorAction,
) => {
  switch (action.type) {
    case actionTypes.SET_SCORING_ITEM_CATEGORIES_SELECTOR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const caseSearchReducer = (state: CaseSearchState = initialCaseSearchState, action: CaseSearchAction) => {
  switch (action.type) {
    case actionTypes.SET_CASE_SEARCH_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const caseCategoriesSelectorReducer = (
  state: CaseCategoriesSelectorState = initialCaseCategoriesSelectorState,
  action: CaseCategoriesSelectorAction,
) => {
  switch (action.type) {
    case actionTypes.SET_CASE_CATEGORIES_SELECTOR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const categorySearchReducer = (
  state: CategorySearchState = initialCategorySearchState,
  action: CategorySearchAction,
) => {
  switch (action.type) {
    case actionTypes.SET_CATEGORY_SEARCH:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const scheduleCasesFilterReducer = (
  state: ScheduleCasesFilterState = initialScheduleCasesFilterState,
  action: ScheduleCasesFilterAction,
) => {
  switch (action.type) {
    case actionTypes.SET_SCHEDULE_CASES_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const scheduleCategoriesFilterReducer = (
  state: ScheduleCategoriesFilterState = initialScheduleCategoriesFilterState,
  action: ScheduleCategoriesFilterAction,
) => {
  switch (action.type) {
    case actionTypes.SET_SCHEDULE_CATEGORIES_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const onlyScheduledReducer = (state: OnlyScheduledState = initialOnlyScheduledState, action: OnlyScheduledAction) => {
  switch (action.type) {
    case actionTypes.SET_ONLY_SCHEDULE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const onlyMineReducer = (state: OnlyMineState = initialOnlyMineState, action: OnlyMineAction) => {
  switch (action.type) {
    case actionTypes.SET_ONLY_MINE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const liveSessionCaseSelectorReducer = (
  state: LiveSessionCaseSelectorState = initialLiveSessionCaseSelectorState,
  action: LiveSessionCaseSelectorAction,
) => {
  switch (action.type) {
    case actionTypes.SET_LIVE_SESSION_CASE_SELECTOR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const liveSessionCategorySelectorReducer = (
  state: LiveSessionCategorySelectorState = initialLiveSessionCategorySelectorState,
  action: LiveSessionCategorySelectorAction,
) => {
  switch (action.type) {
    case actionTypes.SET_LIVE_SESSION_CATEGORY_SELECTOR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const historyCaseSelectorReducer = (
  state: HistoryCaseSelectorState = initialHistoryCaseSelectorState,
  action: HistoryCaseSelectorAction,
) => {
  switch (action.type) {
    case actionTypes.SET_HISTORY_CASE_SELECTOR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const historyCategorySelectorReducer = (
  state: HistoryCategorySelectorState = initialHistoryCategorySelectorState,
  action: HistoryCategorySelectorAction,
) => {
  switch (action.type) {
    case actionTypes.SET_HISTORY_CATEGORY_SELECTOR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const sessionLogCaseSelectorReducer = (
  state: SessionLogCaseSelectorState = initialSessionLogCaseSelectorState,
  action: SessionLogCaseSelectorAction,
) => {
  switch (action.type) {
    case actionTypes.SET_SESSION_LOG_CASE_SELECTOR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const sessionLogCategorySelectorReducer = (
  state: SessionLogCategorySelectorState = initialSessionLogCategorySelectorState,
  action: SessionLogCategorySelectorAction,
) => {
  switch (action.type) {
    case actionTypes.SET_SESSION_LOG_CATEGORY_SELECTOR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const sessionLogCoachFilterReducer = (
  state: SessionLogCoachFilterState = initialSessionLogCoachFilterState,
  action: SessionLogCoachFilterAction,
) => {
  switch (action.type) {
    case actionTypes.SET_SESSION_LOG_COACH_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const sessionLogTraineeFilterReducer = (
  state: SessionLogTraineeFilterState = initialSessionLogTraineeFilterState,
  action: SessionLogTraineeFilterAction,
) => {
  switch (action.type) {
    case actionTypes.SET_SESSION_LOG_TRAINEE_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const institutionSearchReducer = (
  state: InstitutionSearchState = initialInstitutionSearchState,
  action: InstitutionSearchAction,
) => {
  switch (action.type) {
    case actionTypes.SET_INSTITUTION_SEARCH:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const evaluationCoachSearchReducer = (
  state: EvaluationCoachSearchState = initialEvaluationCoachSearchState,
  action: EvaluationCoachSearchAction,
) => {
  switch (action.type) {
    case actionTypes.SET_COACH_EVALUATION_SEARCH:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const evaluationTraineeSearchReducer = (
  state: EvaluationTraineeSearchState = initialEvaluationTraineeSearchState,
  action: EvaluationTraineeSearchAction,
) => {
  switch (action.type) {
    case actionTypes.SET_TRAINEE_EVALUATION_SEARCH:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const caseEvaluationReducer = (
  state: CaseEvaluationState = initialCaseEvaluationState,
  action: CaseEvaluationAction,
) => {
  switch (action.type) {
    case actionTypes.SET_CASE_EVALUATION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const reportingSearchReducer = (
  state: ReportingSearchState = initialReportingSearchState,
  action: ReportingSearchAction,
) => {
  switch (action.type) {
    case actionTypes.SET_REPORTING_SEARCH:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const sessionSetScoreResultReducer = (
  state: SessionConfigState = initialSessionConfigState,
  action: { type: string; payload: ScoreResultDto },
) => {
  if (action.type != actionTypes.SET_SESSION_SCORE_RESULT) {
    return state;
  }

  const newScoreResult = { ...state.session.scoreResult };
  for (const key in action.payload) {
    newScoreResult[key] = action.payload[key];
  }
  return {
    ...state,
    session: {
      ...state.session,
      scoreResult: action.payload,
    },
  };
};

// const sessionSetScoreValueReducer = (
//     state: SessionConfigState = initialSessionConfigState,
//     action: SessionSetScoreResultAction,
// ) => {
//   if (action.type != actionTypes.SESSION_SCORE_VALUE) {
//     return state;
//   }
//   const updatedScoreResult = {...state.session.scoreResult};
//   for (const key in action.payload) {
//     updatedScoreResult[key].value = action.payload[key];
//   }

//   return {
//     ...state,
//     session: {
//       ...state.session,
//       scoreResult: updatedScoreResult,
//     },
//   };
// }

export const rootReducer = combineReducers({
  auth,
  institution,
  videoTitleConfig,
  sessionConfig,
  devicesConfig,
  socketComponentConfig,
  socketFeedbackConfig,
  socketRecordConfig,
  socketManipulationConfig,
  sessionResultReducer,
  sessionTimePlayingReducer,
  sessionEvaluationReducer,
  insightsUsageFilterReducer,
  insightsCasesFilterReducer,
  insightsEvaluationFilterReducer,
  insightsReportingFilterReducer,
  userDateRegistrationFilterReducer,
  usersSearchFilterReducer,
  usersRoleFilterReducer,
  usersSortReducer,
  scoringItemSearchReducer,
  scoringItemCategoriesSelectorReducer,
  caseSearchReducer,
  caseCategoriesSelectorReducer,
  categorySearchReducer,
  scheduleCasesFilterReducer,
  scheduleCategoriesFilterReducer,
  onlyScheduledReducer,
  onlyMineReducer,
  liveSessionCaseSelectorReducer,
  liveSessionCategorySelectorReducer,
  historyCaseSelectorReducer,
  historyCategorySelectorReducer,
  sessionLogCaseSelectorReducer,
  sessionLogCategorySelectorReducer,
  sessionLogCoachFilterReducer,
  sessionLogTraineeFilterReducer,
  institutionSearchReducer,
  evaluationCoachSearchReducer,
  evaluationTraineeSearchReducer,
  caseEvaluationReducer,
  reportingSearchReducer,
  sessionSetScoreResultReducer,
});

export default rootReducer;
