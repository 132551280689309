import React from 'react';

//UI
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';

interface IDefaultHeaderProps {
  title: string;
  primaryAction?: {
    title: string;
    onClick: Function;
    icon?: string;
    disabled?: boolean | undefined;
    type?: 'button' | 'submit' | 'reset';
    hide?: boolean;
  };
  secondaryAction?: {
    title: string;
    onClick: Function;
    icon?: string;
    type?: 'button' | 'submit' | 'reset';
    show?: boolean;
  };
  floatingAction?: {
    title: string;
    onClick: Function;
    show?: boolean;
    textColor: string;
  };
  style?: string;
}

const DefaultHeader = ({ title, primaryAction, secondaryAction, floatingAction, style }: IDefaultHeaderProps) => {
  const hasPrimary = () => {
    return primaryAction && !primaryAction.hide;
  };

  return (
    <div className={style || 'p-6 pb-0'}>
      <div className='flex items-center justify-between'>
        <div className='text-lg font-bold'>{title}</div>
        <div>
          {floatingAction && floatingAction.show && (
            <span
              className={`${floatingAction.textColor} cursor-pointer ${hasPrimary() ? 'mr-4' : ''}`}
              onClick={() => {
                floatingAction.onClick();
              }}>
              {floatingAction.title}
            </span>
          )}
          {secondaryAction && secondaryAction.show && (
            <SecondaryButton
              title={secondaryAction.title}
              onClick={() => secondaryAction.onClick()}
              icon={secondaryAction.icon ? secondaryAction.icon : 'bi bi-plus-circle'}
              type={secondaryAction.type ? secondaryAction.type : 'button'}
              className='mr-4'
            />
          )}
          {primaryAction && !primaryAction.hide && (
            <PrimaryButton
              title={primaryAction.title}
              onClick={() => primaryAction.onClick()}
              icon={primaryAction.icon ? primaryAction.icon : 'bi bi-plus-circle'}
              type={primaryAction.type ? primaryAction.type : 'button'}
              disabled={primaryAction.disabled ? primaryAction.disabled : false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DefaultHeader;
