import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IRouteComponent } from '../../routes';
import { useTranslation } from 'react-i18next';
import DefaultHeader from '../../components/DefaultHeader';
import { InsightsEvaluationCoachDetailsResponseDto } from '../../service/dto/insights.dto';
import InsightsFilterFromUntil from './InsightsFilterFromUntil';
import { setInsightsEvaluationFilterFromUntil } from '../../redux/actions';
import EvaluationCoachTabs, { EvaluationCoachTabsTabContent } from './EvaluationCoachTabs';
import { InsightsService } from '../../service/insightsService';
import { useAppDispatch, useAppSelector } from '../../redux/store';

const EvaluationCoachDetails = ({ currentInstitution }: IRouteComponent) => {
  const { id } = useParams<{ id: string }>();
  const institutionId = currentInstitution.id;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { from, until }: { from: Date; until: Date } = useAppSelector(
    (state: any) => state.insightsEvaluationFilterReducer,
  );
  const [coachDetails, setCoachDetails] = useState<InsightsEvaluationCoachDetailsResponseDto | null>(null);

  useEffect(() => {
    serviceGetCoachDetails();
  }, [from, until, institutionId]);

  useEffect(() => {
    serviceGetCoachDetails();
  }, []);

  // Service fetch
  const serviceGetCoachDetails = () => {
    InsightsService.getEvaluationCoachDetails(
      {
        from: from,
        until: until,
        institutionId: institutionId,
        coachId: id!,
      },
      (data: InsightsEvaluationCoachDetailsResponseDto) => {
        setCoachDetails(data);
      },
      () => {},
    );
  };

  const renderEvaluationCoachTabs = () => {
    const filteredEvaluationDetails: EvaluationCoachTabsTabContent[] = [
      {
        title: t('insights.evaluation.metrics'),
        averageScore: coachDetails?.averageScore,
        averageEvaluationScore: coachDetails?.averageEvaluationScore,
      },
      {
        title: t('table.sessions'),
        sessionTab: coachDetails?.coachSessionsTab,
      },
    ];
    return <EvaluationCoachTabs tabs={filteredEvaluationDetails} />;
  };

  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      {coachDetails && (
        <DefaultHeader
          title={t('sidebar.evaluation') + ' of ' + coachDetails.coachFirstName + ' ' + coachDetails.coachLastName}
        />
      )}
      <InsightsFilterFromUntil
        from={from}
        until={until}
        onChange={({ from, until }) => {
          dispatch(setInsightsEvaluationFilterFromUntil({ from, until }) as any);
        }}
      />
      {renderEvaluationCoachTabs()}
    </div>
  );
};

export default EvaluationCoachDetails;
