import React, { useEffect, useState } from 'react';

import Sidebar from './Sidebar';
import Header from './Header';
import { Outlet } from 'react-router-dom';

type LayoutPrivateProps = {
  children?: React.ReactNode;
};

const LayoutPrivate: React.FC<LayoutPrivateProps> = ({ children }) => {
  return (
    <div className='flex bg-background min-h-screen'>
      <Sidebar />
      <div className='flex-9.5 overflow-auto'>
        <Header />
        {children}
      </div>
    </div>
  );
};

export const LayoutPrivateOutlet = () => {
  const [mounted, setMounted] = useState(true);

  useEffect(() => {
    return () => {
      setMounted(false);
    };
  }, []);

  if (!mounted) return null;

  return (
    <LayoutPrivate>
      <Outlet />
    </LayoutPrivate>
  );
};

export default LayoutPrivate;
