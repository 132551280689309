import {
  ScoreItemDto,
  ScoreResultDto,
  SessionHistoryResponseDto,
  SessionResponseDto,
} from '../service/dto/session.dto';
import { USER_ROLES } from './../common/enums';
import { ICurrentInstitution, ICurrentUser } from './../common/interfaces';
import { setCurrentUserInstitution } from './institution';

export const arrayIncludeArray = (arrayA: any[], arrayB: any[]) => {
  return arrayA.some(r => arrayB.includes(r));
};

export const arrayMove = (arr: any[], old_index: number, new_index: number) => {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};

export const routerRolesAccessAllowed = (
  routerRoles: USER_ROLES[],
  currentUser: ICurrentUser,
  currentInstitution: ICurrentInstitution,
): boolean => {
  // IF not SuperAdmin let's check if router's roles includes user's roles
  if (currentUser.isSuperAdmin) {
    return true;
  }

  const currentUserInstitution = setCurrentUserInstitution({
    currentUser,
    currentInstitution,
  });

  if (currentUserInstitution) {
    return arrayIncludeArray(routerRoles, currentUserInstitution.roles);
  }

  return false;
};

export const formatTime = (value: number) => {
  return value < 10 ? '0' + value : value;
};

export const getDateAndTimeDuration = (sessionItem: SessionResponseDto | SessionHistoryResponseDto) => {
  const diff = sessionItem
    ? sessionItem.caseTemplateSummary.interview_duration + sessionItem.caseTemplateSummary.feedback_duration
    : 0;
  const startDate = new Date(sessionItem.startDate);
  const endDate = new Date(startDate.getTime() + diff * 60000);
  const year = startDate.getFullYear();

  const startTime = formatTime(startDate.getHours()) + ':' + formatTime(startDate.getMinutes());
  const endTime = formatTime(endDate.getHours()) + ':' + formatTime(endDate.getMinutes());

  const monthLabel = startDate.toLocaleString('default', { month: 'short' });
  const day = startDate.getDate();

  return {
    startTime,
    endTime,
    diff,
    year,
    monthLabel,
    day,
    month: startDate.getMonth(),
  };
};

export const addMinutes = (date: Date, minutes: number): Date => {
  return new Date(date.getTime() + minutes * 60000);
};

export const combineDateAndTime = function (date: Date, timeString: string) {
  let parsedHours = Number.parseInt(timeString.split(':')[0]);
  let parsedMinutes = Number.parseInt(timeString.split(':')[1]);
  let newDate = new Date(date);
  newDate.setHours(parsedHours, parsedMinutes);
  return newDate;
};

export const toDHMFormat = (days: number, hours: number, minutes: number): string => {
  return `${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
};

export const toHMSFormat = (hours: number, minutes: number, seconds: number): string => {
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

export const getFirstLetter = (str: string): string => {
  return str.charAt(0);
};

export const getScoreValuesLength = (data: ScoreResultDto) => {
  return Object.values(data)
    .map((scoreEl: ScoreItemDto) => scoreEl.value)
    .filter((scoreVal: number | null) => scoreVal !== null || undefined).length;
};

const hundred = 100,
  numberForRounding = 100;

export const decimalToPercent = (decimal: number): number => {
  return Math.round(decimal * hundred * numberForRounding) / numberForRounding;
};

export const today = (): string => {
  const today: Date = new Date();
  const dd: string | number = today.getDate();
  const mm: string | number = today.getMonth() + 1;
  const yyyy: number = today.getFullYear();

  const modifiedDd = dd < 10 ? '0' + dd : dd;
  const modifiedMm = mm < 10 ? '0' + mm : mm;

  return yyyy + '-' + modifiedMm + '-' + modifiedDd;
};

export const toLocaleDateTimeString = (date: Date): string => {
  const locale = (navigator && navigator.language) || 'de';
  return date.toLocaleString(locale, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
};
