export enum SocketChannels {
  RoomJoined = 'socket:room-joined',
  RoomStatus = 'socket:room-status',
  RoomGetStatus = 'socket:room-get-status',
  TraineeJoinAdHocSession = 'socket:trainee-join-ad-hoc',
  SubscribeCoachToAdHoc = 'socket:subscribe-coach-ad-hoc',
  StartFeedback = 'socket:start-feedback',
  EndSession = 'socket:end-session',
  RecordStatusUpdate = 'socket:record-status-update',
  SessionRecordManipulated = 'socket:session-record-manipulated',
  SessionRecordManipulationListener = 'socket:session-record-manipulation-listener',
  Disconnect = 'socket:disconnect',
  RatingUpdate = 'socket:ratingUpdate',
}
