import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DefaultHeader from '../../components/DefaultHeader';
import { CaseService } from '../../service/case.service';
import { CaseTemplateSummaryDto } from '../../service/dto/case.dto';
import ArchivedTag from '../../components/ArchivedTag';
import { IRouteComponent } from '../../routes';
import SingleCheckboxInput from '../../components/SingleCheckboxInput';
import { DEFAULT_CHECKBOX_STYLE } from '../../utils/default-styles';

const Cases = ({ currentUser }: IRouteComponent) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cases, setCases] = useState<CaseTemplateSummaryDto[]>([]);

  const [showArchived, setShowArchived] = useState<boolean>(false);

  useEffect(() => {
    serviceGetCaseList();
  }, [showArchived]);

  const serviceGetCaseList = () => {
    CaseService.listPublished(
      { archived: showArchived },
      (caseTemplates: Array<CaseTemplateSummaryDto>) => {
        setCases(caseTemplates);
      },
      () => {},
    );
  };

  const caseDetails = (id: string, number: number) => {
    navigate(`/case/detail/` + id);
  };

  const archivedFilterBar = () => {
    return (
      <div className='pt-3 pr-1 flex justify-end'>
        <SingleCheckboxInput
          id={t('common.filterShowArchived')}
          label={t('common.filterShowArchived')}
          handleChange={() => setShowArchived(!showArchived)}
          checked={showArchived}
          style={DEFAULT_CHECKBOX_STYLE}
        />
      </div>
    );
  };

  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      <DefaultHeader title={t('sidebar.casesInsights')} />

      {currentUser.isSuperAdmin && archivedFilterBar()}
      <table className='dataTable table-fixed w-full bg-white px-4 mt-5'>
        <thead>
          <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter'>
            <td className='text-gray-dark text-lg font-bold py-4 w-1/4'>{t('table.case')}</td>
            <td className='text-gray-dark text-lg py-4 w-1/5'>{t('table.actions')}</td>
          </tr>
        </thead>
        <tbody>
          {cases &&
            cases.map((caseName: Cases, index: number) => (
              <tr className='border border-solid border-black-divider' key={caseName.id}>
                <td className='text-gray-dark py-4 text-lg'>
                  {caseName.name} {caseName.archived && <ArchivedTag />}
                </td>
                <td className='py-4'>
                  <div className='flex justify-between flex-wrap text-lg'>
                    <span
                      className='text-primary cursor-pointer pr-4'
                      onClick={() => {
                        caseDetails(caseName.id, index);
                      }}>
                      <i className='bi bi-eye-fill text-primary-hover pr-1 text-xl' /> {t('buttons.session_details')}
                    </span>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Cases;
