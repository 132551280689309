import React from 'react';
import Tag, { TagType } from './Tag';
import { useTranslation } from 'react-i18next';

const ArchivedTag = () => {
  const { t } = useTranslation();

  return <Tag label={t('common.archived')} type={TagType.Red} />;
};
export default ArchivedTag;
