import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

// SERVICE
import { CreateScoringRequestDto, ScoringResponseDto } from '../../service/dto/scoring.dto';
import { ScoringService } from '../../service/scoring.service';

// MODAL
import ModalContent from '../../components/ModalContent';
import AbstractModal from './../../components/AbstractModal';

// FORM
import CreateScoringForm from './CreateScoringForm';

// UI COMPONENTS
import SearchField from '../../components/SearchField';
import ScoringTable from './ScoringTable';

//
import Alert from '../../utils/alert';

import CustomMultiSelect, { ICustomSelectedItem } from '../../components/CustomMultiSelect';
import { IRouteComponent } from '../../routes';
import { CategoryService } from '../../service/category.service';
import { CategoryListItemDto } from '../../service/dto/category.dto';

import { DEFAULT_WEIGHT } from '../../common/lists';
import DefaultHeader from '../../components/DefaultHeader';
import { setScoringItemCategoriesSelector, setScoringItemSearch } from '../../redux/actions';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import SingleCheckboxInput from '../../components/SingleCheckboxInput';
import { DEFAULT_CHECKBOX_STYLE } from '../../utils/default-styles';

enum COMPONENT_STATE {
  LIST,
  CREATE,
  EDIT,
  DELETE,
}

let initialValue = {
  name: '',
  mnemonic: '',
  weight: DEFAULT_WEIGHT,
  grades: 3,
  categoryId: '',
};

const ScoringList = ({ currentUser, currentInstitution }: IRouteComponent) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [state, setState] = useState(COMPONENT_STATE.LIST);

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<ScoringResponseDto[]>([]);
  const { search }: { search: string } = useAppSelector((state: any) => state.scoringItemSearchReducer);
  const { selectedCategories }: { selectedCategories: string[] } = useAppSelector(
    (state: any) => state.scoringItemCategoriesSelectorReducer,
  );

  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [categoryNames, setCategoryNames] = useState<ICustomSelectedItem[]>([]);
  const [categoryList, setCategoryList] = useState<ICustomSelectedItem[]>([]);

  const [data, setData] = useState<CreateScoringRequestDto>(initialValue);
  const [errors, setErrors] = useState({
    name: '',
    mnemonic: '',
  });

  useEffect(() => {
    serviceDataFetch();
  }, [loading, search, selectedCategories, showArchived]);

  useEffect(() => {
    serviceCategoryFetch();
  }, []);

  const onSearch = (value: string) => {
    dispatch(setScoringItemSearch({ search: value }) as any);
  };

  // SERVICE FETCH
  const serviceDataFetch = () => {
    ScoringService.list(
      { search: search, archived: showArchived },
      (data: Array<ScoringResponseDto>) => {
        if (selectedCategories.length) {
          data = data.filter(sI => selectedCategories.indexOf(sI.categoryName) > -1);
        }
        setList(data);
      },
      error => {
        setList([]);
      },
    );
  };

  const serviceCategoryFetch = () => {
    CategoryService.list(
      { archived: false },
      (categories: CategoryListItemDto[]) => {
        setCategoryNames(
          categories.map(category => {
            return { value: category.name, label: category.name };
          }),
        );
        setCategoryList(
          categories.map(category => {
            return { value: category.id, label: category.name };
          }),
        );
        initialValue.categoryId = categories[0].id;
      },
      () => {},
    );
  };

  // SERVICE CREATE
  const serviceCreate = () => {
    ScoringService.create(
      data,
      (data: ScoringResponseDto) => {
        serviceDataFetch();
        setState(COMPONENT_STATE.LIST);
        Alert.success(t('successMessages.scoringItemCreated'));
      },
      error => {
        console.log(error);
        Alert.warning(t('errorMessages.scoringItemNotCreated'));
      },
    );
  };

  // SERVICE UPDATE
  const serviceUpdate = () => {
    ScoringService.update(
      list[selectedIndex].id,
      data,
      (data: ScoringResponseDto) => {
        serviceDataFetch();
        setState(COMPONENT_STATE.LIST);
        Alert.success(t('successMessages.scoringItemUpdated'));
      },
      error => {
        console.log(error);
        Alert.warning(t('errorMessages.scoringItemNotUpdated'));
      },
    );
  };

  // SERVICE DELETE
  const serviceDelete = () => {
    ScoringService.delete(
      list[selectedIndex].id,
      () => {
        serviceDataFetch();
        setState(COMPONENT_STATE.LIST);
        Alert.success(t('successMessages.scoringItemDeleted'));
      },
      error => {
        Alert.warning(t('errorMessages.scoringItemNotDeleted'));
      },
    );
  };

  const handleChange = (fieldName: string, value: any) => {
    const newData = Object.assign({}, data) as any;
    newData[fieldName] = value;
    setData(newData);
  };

  const handleSubmit = () => {
    if (state == COMPONENT_STATE.CREATE) {
      serviceCreate();
    }
    if (state == COMPONENT_STATE.EDIT) {
      serviceUpdate();
    }
  };

  const archivedFilterBar = () => {
    return (
      <div className='pt-3 pr-1 flex justify-end'>
        <SingleCheckboxInput
          id={t('common.filterShowArchived')}
          label={t('common.filterShowArchived')}
          handleChange={() => setShowArchived(!showArchived)}
          checked={showArchived}
          style={DEFAULT_CHECKBOX_STYLE}
        />
      </div>
    );
  };

  const filterBar = () => {
    return (
      <div className='p-6'>
        <div className='flex w-full xl:w-8/12'>
          <div className='mr-6 w-1/3'>
            <SearchField onSearch={onSearch} value={search} />
          </div>

          <div className='flex ml-4 mr-4 w-1/3'>
            <CustomMultiSelect
              placeholderAll='All Categories'
              placeholderSelected='Categories'
              list={categoryNames}
              handleChange={(values: string[]) => {
                dispatch(setScoringItemCategoriesSelector({ selectedCategories: values }) as any);
              }}
              values={selectedCategories}
            />
          </div>
        </div>
      </div>
    );
  };

  const title = t('scoringItems.title') + ' (' + list.length + ')';
  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      <DefaultHeader
        title={title}
        primaryAction={{
          title: t('buttons.createANewScoringItem'),
          onClick: () => {
            setData(initialValue);
            setState(COMPONENT_STATE.CREATE);
          },
        }}
      />

      {currentUser.isSuperAdmin && archivedFilterBar()}
      {filterBar()}

      <ScoringTable
        list={list}
        onDelete={(index: number) => {
          setSelectedIndex(index);
          setState(COMPONENT_STATE.DELETE);
        }}
        onEdit={(index: number) => {
          setSelectedIndex(index);
          setData(list[index]);
          setState(COMPONENT_STATE.EDIT);
        }}
      />

      {(state == COMPONENT_STATE.CREATE || state == COMPONENT_STATE.EDIT) && (
        <AbstractModal
          position={{ width: 'w-1/2', left: 'left-1/3' }}
          label={state == COMPONENT_STATE.CREATE ? t('modals.createScoringItem') : t('modals.updateScoringItem')}
          leftBtn={{
            label: t('buttons.cancel'),
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
          rightBtn={{
            label: state == COMPONENT_STATE.CREATE ? t('buttons.createScoringItem') : t('buttons.updateScoringItem'),
            icon: 'bi-check2-circle',
            onClick: () => {
              handleSubmit();
            },
          }}>
          <CreateScoringForm
            isSelectCategoryDisabled={state == COMPONENT_STATE.EDIT}
            errors={errors ? [errors] : []}
            payload={data}
            handleChange={handleChange}
            categoryList={categoryList}
          />
        </AbstractModal>
      )}

      {state == COMPONENT_STATE.DELETE && (
        <AbstractModal
          position={{ width: 'w-1/3', left: 'left-1/3' }}
          label={t('modals.deleteScoringItem')}
          leftBtn={{
            label: t('buttons.cancel'),
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
          rightBtn={{
            label: t('buttons.yesDelete'),
            onClick: () => {
              serviceDelete();
            },
          }}
          toDelete>
          <ModalContent
            entity='scoring'
            title={t('scoringItems.deleteModal.title')}
            subtitle={t('scoringItems.deleteModal.subtitle')}
          />
        </AbstractModal>
      )}
    </div>
  );
};

export default ScoringList;
