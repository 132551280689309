import React from 'react';
import {useTranslation} from 'react-i18next';
import {Navigate, Outlet} from 'react-router-dom';
import Alert from '../src/utils/alert';
import {USER_ROLES} from './common/enums';
import {ICurrentInstitution, ICurrentUser} from './common/interfaces';
import ForgotPassword from './module/auth/PasswordForgot';
import ResetPassword from './module/auth/PasswordReset';
import Signin from './module/auth/Signin';
import Signup from './module/auth/Signup';
import UserConfirm from './module/auth/UserConfirm';
import CaseArrange from './module/case/CaseArrange';
import CaseList from './module/case/CaseList';
import CreateCaseForm from './module/case/CreateCaseForm';
import CategoryList from './module/category/CategoryList';
import HistoryList from './module/history/HistoryList';
import InstitutionList from './module/institution/InstitutionList';
import HistoryAdminList from './module/log/SessionLogList';
import CreateSessionSlotForm from './module/schedule/CreateSessionSlotForm';
import ScheduleList from './module/schedule/ScheduleList';
import SessionResult from './module/schedule/SessionResult';
import SessionStream from './module/schedule/SessionStream';
import SessionView from './module/schedule/SessionView';
import ScoringList from './module/scoring/ScoringList';
import Dashboard from './module/static/Dashboard';
import Main from './module/static/Main';
import TermsAndConditions from './module/static/TermsAndConditions';
import UserEdit from './module/user/UserEdit';
import UserList from './module/user/UserList';
import UserProfile from './module/user/UserProfile';
import {routerRolesAccessAllowed} from './utils';
import Usage from './module/insights/Usage';
import Cases from './module/insights/Cases';
import Reporting from "./module/insights/Reporting";
import CaseInsights from './module/insights/CaseInsights';
import ImportCaseTemplate from './module/case/ImportCaseTemplate';
import LiveSessionsList from './module/schedule/LiveSessions';
import Evaluation from './module/insights/Evaluation';
import EvaluationTraineeDetails from './module/insights/EvaluationTraineeDetails';
import EvaluationCoachDetails from './module/insights/EvaluationCoachDetails';
import UserPreferences from './module/user/UserPreferences';
import IsNoInstitution from './module/institution/IsNoInstitution';
import {AuthService} from './service/auth.service';
import {useAppSelector} from './redux/store';

export interface IPrivateRouteConfiguration {
  element: React.FC<any>;
  path: string;
  roles?: USER_ROLES[];
}
export interface IPublicRouteConfiguration {
  element: React.FC<any>;
  path: string;
  roles?: USER_ROLES[];
}

export interface IRouteComponent {
  currentUser: ICurrentUser;
  currentInstitution: ICurrentInstitution;
}

export const PublicRoute = ({ path }: { path: string }) => {
  const { user: currentUser, isLoggedIn } = useAppSelector((state: any) => state.auth);

  // Default redirection from public routes to panel
  if (currentUser && isLoggedIn && ['/', '/signin'].includes(path)) {
    return <Navigate to='/dashboard' />;
  }

  return <Outlet />;
};

export const PrivateRoute = ({ roles }: { roles: USER_ROLES[] }) => {
  const { user: currentUser, isLoggedIn } = useAppSelector((state: any) => state.auth);
  const currentInstitution = useAppSelector((state: any) => state.institution);
  const { t } = useTranslation();

  const redirectTo = `?redirectTo=${window.location.hash.substring(
    1,
    window.location.hash.indexOf('?') === -1 ? window.location.hash.length : window.location.hash.indexOf('?'),
  )}`;

  // IF Logged in
  if (!currentUser || !isLoggedIn) {
    return (
      <Navigate
        to={{
          pathname: '/signin',
          search: redirectTo,
        }}
      />
    );
  }

  // Check if user role access allowed for current route
  if (!routerRolesAccessAllowed(roles, currentUser, currentInstitution)) {
    Alert.error(t('errorMessages.routeAccessDenied'));
    if (window.location.href.includes('//')) {
      const signOut = () => new Promise((resolve, reject) => AuthService.signout(() => resolve(null), reject));
      signOut();
      return <Navigate to='/signin' />;
    }
    return <Navigate to='/dashboard' replace />;
  }
  return <Outlet />;
};

export const privateRouteConfigurations: IPrivateRouteConfiguration[] = [
  // SuperAdmin Management
  {
    path: '/institutions',
    element: InstitutionList,
    roles: [USER_ROLES.SuperAdmin],
  },

  // Admin Management
  {
    path: '/users',
    element: UserList,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin],
  },
  {
    path: '/scoring',
    element: ScoringList,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin],
  },
  {
    path: '/cases',
    element: CaseList,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin],
  },
  {
    path: '/case/create',
    element: CreateCaseForm,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin],
  },
  {
    path: '/case/edit/:id',
    element: CreateCaseForm,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin],
  },
  {
    path: '/case/import',
    element: ImportCaseTemplate,
    roles: [USER_ROLES.SuperAdmin],
  },

  // Coach && Trainee
  {
    path: '/case/arrange/:id',
    element: CaseArrange,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin, USER_ROLES.SP, USER_ROLES.Trainee],
  },
  {
    path: '/schedule',
    element: ScheduleList,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin, USER_ROLES.SP, USER_ROLES.Trainee],
  },
  {
    path: '/session/create/:type',
    element: CreateSessionSlotForm,
    roles: [USER_ROLES.Admin, USER_ROLES.SP],
  },
  {
    path: '/session/details/:id',
    element: SessionView,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin, USER_ROLES.SP, USER_ROLES.Trainee],
  },
  {
    path: '/session/result/:id',
    element: SessionResult,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin, USER_ROLES.SP, USER_ROLES.Trainee],
  },
  {
    path: '/session/stream/:id',
    element: SessionStream,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin, USER_ROLES.SP, USER_ROLES.Trainee],
  },
  {
    path: '/session/stream/:id/:shareToken',
    element: SessionStream,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin, USER_ROLES.SP, USER_ROLES.Trainee],
  },
  {
    path: '/history',
    element: HistoryList,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin, USER_ROLES.SP, USER_ROLES.Trainee],
  },
  {
    path: '/log',
    element: HistoryAdminList,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin],
  },
  // Default
  {
    path: '/dashboard',
    element: Dashboard,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin, USER_ROLES.SP, USER_ROLES.Trainee],
  },
  // Profile
  {
    path: '/user/profile',
    element: UserProfile,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin, USER_ROLES.SP, USER_ROLES.Trainee],
  },
  {
    path: '/user/edit',
    element: UserEdit,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin, USER_ROLES.SP, USER_ROLES.Trainee],
  },
  {
    path: '/user/preferences',
    element: UserPreferences,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin, USER_ROLES.SP, USER_ROLES.Trainee],
  },
  {
    path: '/category',
    element: CategoryList,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin],
  },
  {
    path: '/usage',
    element: Usage,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin],
  },
  {
    path: '/insights',
    element: Cases,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin],
  },
  {
    path: '/case/detail/:id',
    element: CaseInsights,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin],
  },
  {
    path: '/live-sessions',
    element: LiveSessionsList,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin, USER_ROLES.SP, USER_ROLES.Trainee],
  },
  {
    path: '/evaluation',
    element: Evaluation,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin],
  },
  {
    path: '/trainee/detail/:id',
    element: EvaluationTraineeDetails,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin],
  },
  {
    path: '/coach/detail/:id',
    element: EvaluationCoachDetails,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin],
  },
  {
    path: '/reporting',
    element: Reporting,
    roles: [USER_ROLES.SuperAdmin, USER_ROLES.Admin],
  }
];

export const publicRouteConfigurations: IPublicRouteConfiguration[] = [
  {
    path: '/',
    element: Main,
  },
  {
    path: '/terms-and-conditions',
    element: TermsAndConditions,
  },
  // Auth routes
  {
    path: '/signin',
    element: Signin,
  },
  {
    path: '/signup/:institutionCode/:role',
    element: Signup,
  },
  {
    path: '/password/reset/:resetPasswordId',
    element: ResetPassword,
  },
  {
    path: '/password/forgot',
    element: ForgotPassword,
  },
  {
    path: '/user/confirm/:confirmationId',
    element: UserConfirm,
  },
  // ELSE - NOT FOUND
  {
    path: '/is-no-institution',
    element: IsNoInstitution,
  },
];
