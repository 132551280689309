import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

// DTO
import {ITeachableSession, ScoreItemDto, ScoreResultDto, SessionResponseDto} from '../service/dto/session.dto';

// UI COMPONENTS
import RecordedOn from './RecordedOn';
import StaticRatingStar from './StaticRatingStar';
import PrimaryButton from './PrimaryButton';

// UTILS
import {DurationMS} from '../utils/constants';

// REDUX
import {RecordSequenceRange, timeSequencesToRanges} from '../utils/funcs';
import {RecordStatus, SessionStatus} from '../common/enums';
import {useAppSelector} from '../redux/store';
import RatingMarks, {IRatingMarksProps} from './RatingMarks';
import {ScoringService} from '../service/scoring.service';
import {SessionService} from '../service/session.service';
import {Simulate} from 'react-dom/test-utils';
import {useParams} from 'react-router-dom';
import {WsType} from "../common/interfaces";
import {WebSocketContext} from "../context/socket";

interface IRecordedOnList {
  scoreResult?: ScoreResultDto;
  sessionFromResult?: ITeachableSession;
  isStar?: boolean;
  isReplay?: boolean;
  recordStatus?: RecordStatus;
  onClick?: (val: number) => void;
  editRatingsPermission?: boolean;
  sessionStatus?: SessionStatus;
  afterUpdateCallback?: (itemId: string, newValue: number) => void;
}

interface ScoringData {
  id: string;
  value: number;
}

const RecordedOnList = ({
  scoreResult,
  sessionFromResult,
  isStar,
  isReplay,
  recordStatus,
  onClick,
  editRatingsPermission,
  sessionStatus,
  afterUpdateCallback,
}: IRecordedOnList) => {
  const { session }: { session: SessionResponseDto } = useAppSelector((state: any) => state.sessionConfig);
  const socket: WsType | null = useContext(WebSocketContext);
  const [scoreItemTimestampToTime, setScoreItemTimestampToTime] = useState<Map<string, number>>(new Map());

  const { t } = useTranslation();
  const { id } = useParams();
  const [ratingToEdit, setRatingToEdit] = useState<number>();
  const [ratingMarksData, setRatingMarksData] = useState<IRatingMarksProps>();
  const [scoringData, setScoringData] = useState<ScoringData>();

  useEffect(() => {
    const _session = sessionFromResult ?? session;
    if (!_session || !scoreResult) {
      setScoreItemTimestampToTime(new Map());
      return;
    }

    let ranges: RecordSequenceRange[] = [];
    if (_session.coachingSessionRecord) {
      ranges = timeSequencesToRanges(
        _session.coachingSessionRecord.recordingSequences,
        _session.coachingSessionRecord.durationInSeconds ?? 1,
      );
    } else if (_session.dailyCoachingSessionRecord && _session.dailyCoachingSessionRecord.durationInSeconds) {
      const from = new Date(_session.dailyCoachingSessionRecord.recordStartTime!).getTime();
      const to = from + (_session.dailyCoachingSessionRecord.durationInSeconds ?? 1) * 1000;
      ranges = [{
        from,
        to,
        fromPercent: 0,
        toPercent: 100,
      }];
    }
    let totalTimeMS = ranges.reduce((sum, el) => sum + el.to - el.from, 0);
    const newItemTimestampToTime = new Map();
    for (const _scoreItem of Object.values(scoreResult).filter(x => x != null && x.value != null)) {
      const timestamp = new Date(_scoreItem.tmstmp).getTime();
      if (totalTimeMS == 0) {
        newItemTimestampToTime.set(_scoreItem.tmstmp, timestamp - new Date(_session.coachingSessionStart).getTime());
        continue;
      }

      const matchedRange = ranges.find(tr => timestamp >= tr.from && timestamp <= tr.to);
      if (!matchedRange) {
        continue;
      }

      const progress =
        ((timestamp - matchedRange.from) / (matchedRange.to - matchedRange.from)) * matchedRange.toPercent +
        matchedRange.fromPercent;
      newItemTimestampToTime.set(_scoreItem.tmstmp, (totalTimeMS * progress) / 100);
    }
    setScoreItemTimestampToTime(newItemTimestampToTime);
  }, [scoreResult, session]);

  useEffect(() => {
    changeScoringItem()
  }, [scoringData]);

  const getDiffDatesStr = (firstDateStr: string, secondDateStr: string): number => {
    const firstDateInMS: number = new Date(firstDateStr).getTime();
    const secondDateInMS: number = new Date(secondDateStr).getTime();

    return secondDateInMS - firstDateInMS;
  };

  const msToStrViewMS = (ms: number, tmstmp: string): string => {
    if (ms > 0) {
      let seconds: number = ms / DurationMS.SEC;
      const minutes = parseInt((ms / DurationMS.MIN).toString());
      const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

      // Keep only seconds not extracted to minutes:
      seconds = parseInt((seconds % 60).toString());
      const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
      return formattedMinutes + ':' + formattedSeconds;
    } else {
      return new Date(tmstmp).toLocaleString('en-GB');
    }
  };

  const onReplayClick = (item: ScoreItemDto) => {
    if (!onClick || !isReplay) {
      return;
    }
    const time = scoreItemTimestampToTime.get(item.tmstmp);
    onClick(time || 0);
  };

  const handleScore = (id: string, value: number) => {
    setScoringData({ id, value });
  };

  const setRatingStar = (tmstp: string, name: string) => {
    const ratingStarId: string = Object.keys(scoreResult!!).find(key => scoreResult!![key].tmstmp === tmstp) ?? '';
    ScoringService.grade(
      ratingStarId,
      (_data: any) => {
        setRatingMarksData({ data: { id: ratingStarId, name, grades: _data }, handleScore });
      },
      error => {
        console.log(error);
      },
    );
  };

  const changeScoringItem = () => {
    if (!scoringData) {
      return;
    }

    SessionService.changeScore(
      id!,
      scoringData.id,
      {
        value: scoringData.value,
      },
      (_data: any) => {
        setRatingToEdit(-1);
        afterUpdateCallback?.(scoringData.id, scoringData.value);
        // check it later 
        // TODO: check if it is needed
        // socket!.coachUpdateRating(session.id, scoringData.id, scoringData.value);
        // window.location.reload();
      },
      error => {
        console.log(error);
      },
    );
  };

  return (
    <>
      {scoreResult &&
        Object.values(scoreResult)
          .filter(el => !!el.value)
          .sort((a, b) => getDiffDatesStr(b.tmstmp, a.tmstmp))
          .map((item: ScoreItemDto, j: number) => (
            <div key={item.name + j}>
              <div className='flex'>
                {isStar && (
                  <div className='mr-6'>
                    <StaticRatingStar
                      rate={item.value}
                      onClick={() => {
                        onReplayClick(item);
                      }}
                      tmstmp={item.tmstmp}
                    />
                  </div>
                )}
                <div className='w-full'>
                  <div className='flex justify-between'>
                    <RecordedOn
                      listNumber={j + 1}
                      title={item.name}
                      recordedTime={
                        item.tmstmp &&
                        scoreItemTimestampToTime.has(item.tmstmp) &&
                        !!scoreItemTimestampToTime.get(item.tmstmp)
                          ? msToStrViewMS(scoreItemTimestampToTime.get(item.tmstmp)!, item.tmstmp)
                          : undefined
                      }
                      savedRatingMark={item.value}
                    />
                    {(() => {
                      if (!isReplay) {
                        return <></>;
                      }

                      if (recordStatus == RecordStatus.Aborted) {
                        return (
                          <div className='italic self-center'>{t('session.recording.replayButtonUnavailable')}</div>
                        );
                      }

                      if (recordStatus !== RecordStatus.Available) {
                        return <></>;
                      }

                      if (scoreItemTimestampToTime.get(item.tmstmp)! < 0) {
                        return <></>;
                      }

                      return (
                        <PrimaryButton
                          onClick={() => {
                            onReplayClick(item);
                          }}
                          title={t('buttons.replay')}
                        />
                      );
                    })()}
                    {editRatingsPermission && ratingToEdit != j && (
                      <PrimaryButton
                        title={t('buttons.updateRating')}
                        className={'!text-primary !bg-background !font-normal !px-0'}
                        textTransform={'capitalize'}
                        onClick={() => {
                          setRatingToEdit(j);
                          setRatingStar(item.tmstmp, item.name);
                        }}
                      />
                    )}
                    {ratingMarksData && (
                      <div key={item.name + j} className={`${ratingToEdit == j ? 'flex' : 'hidden'} py-2`}>
                        <div className={sessionStatus === SessionStatus.Feedback ? 'max-h-4' : ''}>
                          <RatingMarks
                            data={ratingMarksData.data}
                            handleScore={ratingMarksData.handleScore}
                            savedScore={ratingMarksData.savedScore}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <hr className='w-full h-px bg-black-divider border-0 mt-3 mb-4' />
                </div>
              </div>
            </div>
          ))}
    </>
  );
};

export default RecordedOnList;
