import {
  AuthDispatchType,
  DevicesDispatchType,
  DeviceState,
  InstitutionDispatchType,
  SessionConfigDispatchType,
  SessionConfigState,
  SessionEvaluationDispatchType,
  SessionEvaluationState,
  SocketComponentDispatchType,
  SocketComponentState,
  SocketFeedbackDispatchType,
  SocketFeedbackState,
  SocketManipulationDispatchType,
  SocketManipulationState,
  SocketRecordDispatchType,
  SocketRecordState,
  SocketSessionDispatchType,
  SocketSessionState,
  VideoTitleConfigDispatchType,
  VideoTitleConfigState,
  SessionResultState,
  SessionResultDispatchType,
  SessionTimePlayingState,
  SessionTimePlayingDispatchType,
  InsightsUsageFilterState,
  InsightsUsageFilterDispatchType,
  InsightsCasesFilterState,
  InsightsCasesFilterDispatchType,
  InsightsEvaluationFilterState,
  InsightsEvaluationFilterDispatchType,
  InsightsReportingFilterState,
  InsightsReportingFilterDispatchType,
  UserDateRegistrationFilterState,
  UserDateRegistrationFilterDispatchType,
  UsersSearchFilterState,
  UsersSearchFilterDispatchType,
  UsersRoleFilterState,
  UsersRoleFilterDispatchType,
  UsersSortState,
  UsersSortDispatchType,
  ScoringItemSearchState,
  ScoringItemSearchDispatchType,
  ScoringItemCategoriesSelectorState,
  ScoringItemCategoriesSelectorDispatchType,
  CaseSearchState,
  CaseSearchDispatchType,
  CaseCategoriesSelectorState,
  CaseCategoriesSelectorDispatchType,
  CategorySearchState,
  CategorySearchDispatchType,
  ScheduleCasesFilterState,
  ScheduleCasesFilterDispatchType,
  ScheduleCategoriesFilterState,
  ScheduleCategoriesFilterDispatchType,
  OnlyScheduledState,
  OnlyScheduledDispatchType,
  OnlyMineState,
  OnlyMineDispatchType,
  LiveSessionCaseSelectorState,
  LiveSessionCaseSelectorDispatchType,
  LiveSessionCategorySelectorState,
  LiveSessionCategorySelectorDispatchType,
  HistoryCaseSelectorState,
  HistoryCaseSelectorDispatchType,
  HistoryCategorySelectorState,
  HistoryCategorySelectorDispatchType,
  SessionLogCaseSelectorState,
  SessionLogCaseSelectorDispatchType,
  SessionLogCategorySelectorDispatchType,
  SessionLogCategorySelectorState,
  SessionLogCoachFilterState,
  SessionLogCoachFilterDispatchType,
  SessionLogTraineeFilterDispatchType,
  SessionLogTraineeFilterState,
  InstitutionSearchState,
  InstitutionSearchDispatchType,
  EvaluationCoachSearchState,
  EvaluationCoachSearchAction,
  EvaluationCoachSearchDispatchType,
  EvaluationTraineeSearchDispatchType,
  EvaluationTraineeSearchState,
  CaseEvaluationState,
  CaseEvaluationDispatchType,
  ReportingSearchState,
  ReportingSearchDispatchType,
  SetSessionScoreValueAction,
} from './types.d';
import { ICurrentInstitution } from '../common/interfaces';
import { actionTypes } from './constants';
import { UserResponseDto } from '../service/dto/user.dto';
import { createAction } from '@reduxjs/toolkit';
import exp from 'constants';
import { ScoreResultDto, TeachableMomentDto } from '../service/dto/session.dto';
import { SessionStatus } from '../common/enums';

// AUTH/USER ACTION CREATOR
export const actionSignin = createAction<UserResponseDto>(actionTypes.SIGNIN);
export const signin = (user: UserResponseDto) => (dispatch: AuthDispatchType) =>
  dispatch({ type: actionTypes.SIGNIN, payload: user });

export const actionSignup = createAction(actionTypes.SIGNUP);
export const signup = () => (dispatch: AuthDispatchType) => dispatch({ type: actionTypes.SIGNUP });

export const currentUser = () => (dispatch: AuthDispatchType) => dispatch({ type: actionTypes.CURRENTUSER });

export const actionSignout = createAction(actionTypes.SIGNOUT);
export const signout = () => (dispatch: AuthDispatchType) => dispatch({ type: actionTypes.SIGNOUT });

export const updateMe = (user: UserResponseDto) => (dispatch: AuthDispatchType) =>
  dispatch({ type: actionTypes.UPDATE_ME, payload: user });

// INSTITUTION ACTION CREATOR
export const actionToggleInstitution = createAction<ICurrentInstitution>(actionTypes.TOGGLE_INSTITUTION);
export const toggleInstitution = (institution: ICurrentInstitution) => (dispatch: InstitutionDispatchType) => {
  dispatch({ type: actionTypes.TOGGLE_INSTITUTION, payload: institution });
};

// RECORDED VIDEO MODAL ACTION CREATOR
export const actionSetVideoTitleConfig = createAction<VideoTitleConfigState>(actionTypes.SET_VIDEO_TITLE_CONFIG);

export const setVideoTitleConfig = (config: VideoTitleConfigState) => (dispatch: VideoTitleConfigDispatchType) => {
  dispatch({ type: actionTypes.SET_VIDEO_TITLE_CONFIG, payload: config });
};

// SESSION ACTION CREATOR
export const actionSetSessionConfig = createAction<SessionConfigState>(actionTypes.SET_SESSION_CONFIG);

export const setSessionConfig = (config: SessionConfigState) => (dispatch: SessionConfigDispatchType) => {
  dispatch({ type: actionTypes.SET_SESSION_CONFIG, payload: config });
};

// DEVICES SET ACTION
export const actionSetDevices = createAction<DeviceState>(actionTypes.SET_DEVICES);
export const setDevices = (device: DeviceState) => (dispatch: DevicesDispatchType) => {
  dispatch({ type: actionTypes.SET_DEVICES, payload: device });
};

//SOCKETS
export const actionSetRoomStatus = createAction<SocketComponentState>(actionTypes.ROOM_STATUS);
export const setRoomStatus = (socket: SocketComponentState) => (dispatch: SocketComponentDispatchType) => {
  dispatch({ type: actionTypes.ROOM_STATUS, payload: socket });
};

export const actionSetStartFeedback = createAction<SocketFeedbackState>(actionTypes.START_FEEDBACK);
export const setStartFeedback = (socket: SocketFeedbackState) => (dispatch: SocketFeedbackDispatchType) => {
  dispatch({ type: actionTypes.START_FEEDBACK, payload: socket });
};
export const actionSetEndSession = createAction<SocketSessionState>(actionTypes.END_SESSION);
export const setEndSession = (socket: SocketSessionState) => (dispatch: SocketSessionDispatchType) => {
  dispatch({ type: actionTypes.END_SESSION, payload: socket });
};
export const actionSetRecordStatus = createAction<SocketRecordState>(actionTypes.RECORD_STATUS_UPDATE);
export const setRecordStatus = (socket: SocketRecordState) => (dispatch: SocketRecordDispatchType) => {
  dispatch({ type: actionTypes.RECORD_STATUS_UPDATE, payload: socket });
};
export const actionSetSessionManipulationListener = createAction<SocketManipulationState>(
  actionTypes.SESSION_RECORD_MANIPULATION_LISTENER,
);
export const setSessionManipulationListener =
  (socket: SocketManipulationState) => (dispatch: SocketManipulationDispatchType) => {
    dispatch({ type: actionTypes.SESSION_RECORD_MANIPULATION_LISTENER, payload: socket });
  };

export const actionSocketSessionEnd = createAction(actionTypes.SESSION_END);
export const socketSessionEnd = () => (dispatch: any) => {
  dispatch({ type: actionTypes.SESSION_END });
};

//
export const setSessionResult = (payload: SessionResultState) => ({
  type: actionTypes.SET_SESSION_RESULT,
  payload,
});

export const actionSetSessionTimePlaying = createAction<SessionTimePlayingState>(actionTypes.SET_SESSION_TIME_PLAYING);
export const setSessionTimePlaying =
  (payload: SessionTimePlayingState) => (dispatch: SessionTimePlayingDispatchType) => {
    dispatch({ type: actionTypes.SET_SESSION_TIME_PLAYING, payload });
  };

export const setSessionEvaluation = (payload: SessionEvaluationState) => (dispatch: SessionEvaluationDispatchType) => {
  dispatch({ type: actionTypes.SESSION_EVALUATION_UPDATE, payload });
};

export const setInsightsUsageFilterFromUntil =
  (payload: InsightsUsageFilterState) => (dispatch: InsightsUsageFilterDispatchType) => {
    dispatch({ type: actionTypes.SET_INSIGHTS_USAGE_FILTER_FROM_UNTIL, payload });
  };

export const setInsightsCasesFilterFromUntil =
  (payload: InsightsCasesFilterState) => (dispatch: InsightsCasesFilterDispatchType) => {
    dispatch({ type: actionTypes.SET_INSIGHTS_CASES_FILTER_FROM_UNTIL, payload });
  };

export const setInsightsEvaluationFilterFromUntil =
  (payload: InsightsEvaluationFilterState) => (dispatch: InsightsEvaluationFilterDispatchType) => {
    dispatch({ type: actionTypes.SET_INSIGHTS_EVALUATION_FILTER_FROM_UNTIL, payload });
  };

export const setInsightsReportingFilterFromUntil =
  (payload: InsightsReportingFilterState) => (dispatch: InsightsReportingFilterDispatchType) => {
    dispatch({ type: actionTypes.SET_INSIGHTS_REPORTING_FILTER_FROM_UNTIL, payload });
  };

//Filter
export const setUserDateRegistrationFilterFromUntil =
  (payload: UserDateRegistrationFilterState) => (dispatch: UserDateRegistrationFilterDispatchType) => {
    dispatch({ type: actionTypes.SET_USER_DATE_REGISTRATION_FILTER_FROM_UNTIL, payload });
  };

export const setUsersSearchFilter = (payload: UsersSearchFilterState) => (dispatch: UsersSearchFilterDispatchType) => {
  dispatch({ type: actionTypes.SET_USERS_SEARCH_FILTER, payload });
};

export const setUsersRoleFilter = (payload: UsersRoleFilterState) => (dispatch: UsersRoleFilterDispatchType) => {
  dispatch({ type: actionTypes.SET_USERS_ROLE_FILTER, payload });
};

export const setUsersSort = (payload: UsersSortState) => (dispatch: UsersSortDispatchType) => {
  dispatch({ type: actionTypes.SET_USERS_SORT_BY, payload });
};

export const setScoringItemSearch = (payload: ScoringItemSearchState) => (dispatch: ScoringItemSearchDispatchType) => {
  dispatch({ type: actionTypes.SET_SCORING_ITEM_FILTER, payload });
};

export const setScoringItemCategoriesSelector =
  (payload: ScoringItemCategoriesSelectorState) => (dispatch: ScoringItemCategoriesSelectorDispatchType) => {
    dispatch({ type: actionTypes.SET_SCORING_ITEM_CATEGORIES_SELECTOR, payload });
  };

export const setCaseSearchSelector = (payload: CaseSearchState) => (dispatch: CaseSearchDispatchType) => {
  dispatch({ type: actionTypes.SET_CASE_SEARCH_FILTER, payload });
};

export const setCaseCategoriesSelector =
  (payload: CaseCategoriesSelectorState) => (dispatch: CaseCategoriesSelectorDispatchType) => {
    dispatch({ type: actionTypes.SET_CASE_CATEGORIES_SELECTOR, payload });
  };

export const setCategorySearchSelector = (payload: CategorySearchState) => (dispatch: CategorySearchDispatchType) => {
  dispatch({ type: actionTypes.SET_CATEGORY_SEARCH, payload });
};

export const setScheduleCasesFilterSelector =
  (payload: ScheduleCasesFilterState) => (dispatch: ScheduleCasesFilterDispatchType) => {
    dispatch({ type: actionTypes.SET_SCHEDULE_CASES_FILTER, payload });
  };

export const setScheduleCategoriesFilterSelector =
  (payload: ScheduleCategoriesFilterState) => (dispatch: ScheduleCategoriesFilterDispatchType) => {
    dispatch({ type: actionTypes.SET_SCHEDULE_CATEGORIES_FILTER, payload });
  };

export const setOnlyScheduledSelector = (payload: OnlyScheduledState) => (dispatch: OnlyScheduledDispatchType) => {
  dispatch({ type: actionTypes.SET_ONLY_SCHEDULE, payload });
};

export const setOnlyMineSelector = (payload: OnlyMineState) => (dispatch: OnlyMineDispatchType) => {
  dispatch({ type: actionTypes.SET_ONLY_MINE, payload });
};

export const setLiveSessionCaseSelector =
  (payload: LiveSessionCaseSelectorState) => (dispatch: LiveSessionCaseSelectorDispatchType) => {
    dispatch({ type: actionTypes.SET_LIVE_SESSION_CASE_SELECTOR, payload });
  };

export const setLiveSessionCategorySelector =
  (payload: LiveSessionCategorySelectorState) => (dispatch: LiveSessionCategorySelectorDispatchType) => {
    dispatch({ type: actionTypes.SET_LIVE_SESSION_CATEGORY_SELECTOR, payload });
  };

export const setHistoryCaseSelector =
  (payload: HistoryCaseSelectorState) => (dispatch: HistoryCaseSelectorDispatchType) => {
    dispatch({ type: actionTypes.SET_HISTORY_CASE_SELECTOR, payload });
  };

export const setHistoryCategorySelector =
  (payload: HistoryCategorySelectorState) => (dispatch: HistoryCategorySelectorDispatchType) => {
    dispatch({ type: actionTypes.SET_HISTORY_CATEGORY_SELECTOR, payload });
  };

export const setSessionLogCaseSelector =
  (payload: SessionLogCaseSelectorState) => (dispatch: SessionLogCaseSelectorDispatchType) => {
    dispatch({ type: actionTypes.SET_SESSION_LOG_CASE_SELECTOR, payload });
  };

export const setSessionLogCategorySelector =
  (payload: SessionLogCategorySelectorState) => (dispatch: SessionLogCategorySelectorDispatchType) => {
    dispatch({ type: actionTypes.SET_SESSION_LOG_CATEGORY_SELECTOR, payload });
  };

export const setSessionLogCoachFilterSelector =
  (payload: SessionLogCoachFilterState) => (dispatch: SessionLogCoachFilterDispatchType) => {
    dispatch({ type: actionTypes.SET_SESSION_LOG_COACH_FILTER, payload });
  };

export const setSessionLogTraineeFilterSelector =
  (payload: SessionLogTraineeFilterState) => (dispatch: SessionLogTraineeFilterDispatchType) => {
    dispatch({ type: actionTypes.SET_SESSION_LOG_TRAINEE_FILTER, payload });
  };

export const setInstitutionSearchSelector =
  (payload: InstitutionSearchState) => (dispatch: InstitutionSearchDispatchType) => {
    dispatch({ type: actionTypes.SET_INSTITUTION_SEARCH, payload });
  };

export const setEvaluationCoachSearchSelector =
  (payload: EvaluationCoachSearchState) => (dispatch: EvaluationCoachSearchDispatchType) => {
    dispatch({ type: actionTypes.SET_COACH_EVALUATION_SEARCH, payload });
  };

export const setEvaluationTraineeSearchSelector =
  (payload: EvaluationTraineeSearchState) => (dispatch: EvaluationTraineeSearchDispatchType) => {
    dispatch({ type: actionTypes.SET_TRAINEE_EVALUATION_SEARCH, payload });
  };

export const setCaseEvaluationSelector = (payload: CaseEvaluationState) => (dispatch: CaseEvaluationDispatchType) => {
  dispatch({ type: actionTypes.SET_CASE_EVALUATION, payload });
};

export const setReportingSearchSelector =
  (payload: ReportingSearchState) => (dispatch: ReportingSearchDispatchType) => {
    dispatch({ type: actionTypes.SET_REPORTING_SEARCH, payload });
  };

export const setSessionScoreResult = createAction<ScoreResultDto>(actionTypes.SET_SESSION_SCORE_RESULT);
export const setSessionScoreValue = createAction<{score: {[key: string]: {value: number, tmstmp: number}}, sessionId: string}>(actionTypes.SESSION_SCORE_VALUE);
export const setSessionStatus = createAction<SessionStatus>(actionTypes.SET_SESSION_STATUS);
export const setSessionTeachableMoments = createAction<TeachableMomentDto[]>(actionTypes.SET_SESSION_TEACHABLE_MOMENTS);
export const setSessionTotalScore = createAction<number>(actionTypes.SET_SESSION_TOTAL_SCORE);
