import React from 'react';
import { useTranslation } from 'react-i18next';
import { SessionStatus, SessionType, USER_ROLES } from '../../common/enums';
import { ICurrentUser } from '../../common/interfaces';
import Tag, { TagType } from '../../components/Tag';
import { SessionResponseDto } from '../../service/dto/session.dto';
import { getDateAndTimeDuration } from '../../utils';

interface ITableProps {
  sessionList: Array<SessionResponseDto>;
  currentInstitution: any;
  roles: USER_ROLES[];
  cancelSession: (id: string, number: number) => void;
  detailsSession: (id: string, number: number) => void;
  resultSession: (id: string, number: number) => void;
  currentUser: ICurrentUser;
  sessionType?: SessionType;
}

const ScheduleTable = ({
  sessionList,
  roles,
  cancelSession,
  detailsSession,
  resultSession,
  currentUser,
  sessionType = SessionType.RegularSession,
}: ITableProps) => {
  const { t } = useTranslation();

  const renderTime = (index: number) => {
    // Get item from list by array index
    const sessionItem: SessionResponseDto = sessionList[index];

    const { startTime, endTime, diff, monthLabel, month, day } = getDateAndTimeDuration(sessionItem);
    const today: Date = new Date();

    // Check if show date label withing the same day sessions
    let showDateLabel = false;
    if (index != 0 && sessionList[index - 1]) {
      const prev = sessionList[index - 1];
      const prevDate = new Date(prev.startDate);
      if (prevDate.getMonth() != month || prevDate.getDate() != day) {
        showDateLabel = true;
      }
    } else {
      showDateLabel = true;
    }

    const dateLabel = today.getMonth() == month && today.getDate() == day ? 'Today' : day + ' ' + monthLabel;

    return (
      <div className='flex'>
        <div className={`w-20${showDateLabel ? '' : ' text-transparent'}`}>{dateLabel}</div>
        <div>
          <div>
            {startTime} - {endTime}
          </div>
          <span className={'font-thin text-sm text-gray'}>{diff} MIN</span>
        </div>
      </div>
    );
  };

  const renderNameAndLocation = (index: number) => {
    const sessionItem: SessionResponseDto = sessionList[index];
    const { name } = sessionItem.caseTemplateSummary;
    var { location } = sessionItem;
    const isOffline = !(location === '' || location === undefined);

    return (
      <div className='flex'>
        <div>
          <div>{name}</div>
          <span className={`text-sm text-${isOffline ? 'negative-darker' : 'primary'}`}>
            {isOffline ? `📍 ${location?.toUpperCase()}` : '🌐 ONLINE'}
          </span>
        </div>
      </div>
    );
  };

  const renderParticipants = (index: number) => {
    const sessionItem: SessionResponseDto = sessionList[index];
    const { coach, trainee } = sessionItem;

    return (
      <div className='flex'>
        <div>
          {roles.includes(USER_ROLES.Trainee) && <div>👨‍🏫 {coach ? `${coach.firstName} ${coach.lastName}` : ''}</div>}
          {(roles.includes(USER_ROLES.SP) || trainee?.id === currentUser.id) && (
            <div>🎓 {trainee ? `${trainee.firstName} ${trainee.lastName}` : ''}</div>
          )}
        </div>
      </div>
    );
  };

  const renderActions = (index: number) => {
    const sessionItem: SessionResponseDto = sessionList[index];
    return (
      <div className='text-right'>
      <div className='flex flex-col'>
        <div>
          <span
            className='text-primary cursor-pointer'
            onClick={() => {
              if (sessionItem.status === SessionStatus.Finished) {
                resultSession(sessionItem.id, index);
              } else {
                detailsSession(sessionItem.id, index);
              }
            }}>
            {t('buttons.session_details')}
          </span>
        </div>
        <div>
          {sessionItem.coach.id === currentUser.id && sessionItem.status !== SessionStatus.Finished ? (
            <span
              className='text-negative-darker cursor-pointer'
              onClick={() => {
                cancelSession(sessionItem.id, index);
              }}>
              {t('buttons.cancel_session')}
            </span>
          ) : (
            <>
              {sessionItem.status == SessionStatus.Scheduled &&
                (sessionItem.coach.id === currentUser.id || sessionItem.trainee.id === currentUser.id) && (
                  <>
                    <span
                      className='text-negative-darker cursor-pointer'
                      onClick={() => {
                        cancelSession(sessionItem.id, index);
                      }}>
                      {t('buttons.cancel_session')}
                    </span>
                  </>
                )}
            </>
          )}
        </div></div>
      </div>
    );
  };

  return (
    <table className='dataTable table-fixed w-full bg-white px-4'>
      <thead>
        <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter'>
          {sessionType === SessionType.RegularSession && <td className='py-4 w-1/5'>{t('table.time')}</td>}
          <td className='text-gray-dark font-bold py-4 w-1/4'>{t('table.case')}</td>
          <td className='text-gray-dark font-bold py-4 w-1/6'>{t('table.category')}</td>
          <td className='text-gray-dark font-bold py-4 w-1/6'>{t('table.participants')}</td>
          <td className='text-gray-dark font-bold py-4 w-1/12 text-center'>{t('table.status')}</td>
          <td className='flex text-gray-dark py-4 w-1/8 justify-end'>{t('table.actions')}</td>
        </tr>
      </thead>

      <tbody>
        {sessionList.map((sessionItem: SessionResponseDto, index: number) => {
          return (
            <tr className='border border-solid border-black-divider' key={sessionItem.id}>
              {sessionType === SessionType.RegularSession && (
                <td className='text-gray-dark py-4'>{renderTime(index)}</td>
              )}

              <td className='text-gray-dark py-4'>{renderNameAndLocation(index)}</td>
              <td className='text-gray-dark py-4'>{sessionItem.categoryName}</td>
              <td className='text-gray-dark py-4'>{renderParticipants(index)}</td>

              <td className='py-4 text-center'>
                {sessionItem.status == SessionStatus.Open && <Tag label='Open' type={TagType.Yellow} />}
                {sessionItem.status == SessionStatus.Scheduled && <Tag label='Scheduled' type={TagType.Green} />}
                {sessionItem.status == SessionStatus.Coaching && <Tag label='Coaching' type={TagType.Green} />}
                {sessionItem.status == SessionStatus.Feedback && <Tag label='Feedback' type={TagType.Green} />}
                {sessionItem.status == SessionStatus.Finished && <Tag label='Finished' type={TagType.Blue} />}
              </td>

              <td className='py-4'>{renderActions(index)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ScheduleTable;
