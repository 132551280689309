import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultHeader from '../../components/DefaultHeader';
import SingleCheckboxInput from '../../components/SingleCheckboxInput';
import {
  CoachNotificationsResponseDto,
  TraineeNotificationsResponseDto,
  UserPreferencesResponseDto,
} from '../../service/dto/user-preferences.dto';
import { UserPreferencesService } from '../../service/user-preferences.service';
import Alert from '../../utils/alert';
import { IRouteComponent } from '../../routes';
import { AuthState } from '../../redux/types';
import { USER_ROLES } from '../../common/enums';
import { useAppSelector } from '../../redux/store';

const UserPreferences = ({ currentInstitution }: IRouteComponent) => {
  const { t } = useTranslation();
  const { user: currentUser } = useAppSelector((state: any) => state.auth as AuthState);
  const [preferences, setPreferences] = useState<UserPreferencesResponseDto>();
  const [showTrainee, setShowTrainee] = useState(false);
  const [showCoach, setShowCoach] = useState(false);

  useEffect(() => {
    serviceGetUserPreferences();
    const institutionRelation = currentUser?.institutions?.find(x => x.institutionId == currentInstitution.id);
    setShowCoach(institutionRelation?.roles?.some(x => x == USER_ROLES.SP) ?? false);
    setShowTrainee(institutionRelation?.roles?.some(x => x == USER_ROLES.Trainee) ?? false);
  }, [currentUser]);

  const updateCoach = (key: keyof CoachNotificationsResponseDto, value: boolean) => {
    if (!preferences) {
      return;
    }
    const coach = {
      ...preferences.notifications.coach,
    };
    coach[key] = value;
    setPreferences({
      ...preferences,
      notifications: {
        ...preferences.notifications,
        coach,
      },
    });
  };

  const updateTrainee = (key: keyof TraineeNotificationsResponseDto, value: boolean) => {
    if (!preferences) {
      return;
    }
    const trainee = {
      ...preferences.notifications.trainee,
    };
    trainee[key] = value;
    setPreferences({
      ...preferences,
      notifications: {
        ...preferences.notifications,
        trainee,
      },
    });
  };

  // Service fetch
  const serviceGetUserPreferences = () => {
    UserPreferencesService.get(
      (data: UserPreferencesResponseDto) => {
        setPreferences(data);
      },
      () => {},
    );
  };

  const serviceUpdateUserPreferences = () => {
    if (!preferences) {
      return;
    }
    UserPreferencesService.update(
      preferences.id,
      {
        id: preferences.id,
        notifications: preferences.notifications,
        dailySummary: preferences.dailySummary,
      },
      (data: UserPreferencesResponseDto) => {
        setPreferences(data);
        Alert.success(t('successMessages.notificationsUpdate'));
      },
      () => {},
    );
  };

  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      <div className='container p-6'>
        <div className='my-6 mx-4 2xl:mx-6'>
          <div className='w-full'>
            <DefaultHeader
              title={t('layout.preferences') + ' for ' + currentInstitution.name}
              style={'pb-2'}
              primaryAction={{
                title: t('buttons.save'),
                onClick: () => serviceUpdateUserPreferences(),
                icon: 'bi bi-check2-circle',
                type: 'submit',
              }}
            />
            <hr className='w-full h-px bg-black-divider border-0 mt-4 mb-6' />
            <section></section>
            {preferences && (
              <div className={'mt-5'}>
                <h2>{t('preferences.systemNotifications')}</h2>
                <p className={'mt-1'}>{t('preferences.receiveAnEmailNotification')}</p>
                <h4 className={'my-3'}>{t('preferences.dailySummary')}</h4>
                <SingleCheckboxInput
                  value={'dailySummary'}
                  label={t('preferences.receiveDailySummary')}
                  id={'dailySummary'}
                  handleChange={event => {
                    setPreferences({ ...preferences, dailySummary: event.target.checked });
                  }}
                  checked={preferences.dailySummary}
                />
                {showCoach && (
                  <div>
                    <fieldset className={'mt-5'}>
                      <h4 className={'mb-3'}>{t('preferences.coachScheduling')}</h4>
                      <SingleCheckboxInput
                        value={'traineeBooksSession'}
                        label={t('preferences.traineeBooks')}
                        id={'traineeBooks'}
                        handleChange={event => {
                          updateCoach('traineeBooksSession', event.target.checked);
                        }}
                        checked={preferences.notifications.coach.traineeBooksSession}
                      />
                      <SingleCheckboxInput
                        value={'traineeCancelsSession'}
                        label={t('preferences.traineeCancels')}
                        id={'traineeCancels'}
                        handleChange={event => {
                          updateCoach('traineeCancelsSession', event.target.checked);
                        }}
                        checked={preferences.notifications.coach.traineeCancelsSession}
                      />
                      <SingleCheckboxInput
                        value={'coachReminder72H'}
                        label={t('preferences.72h')}
                        id={'72hCoach'}
                        checked={preferences.notifications.coach.coachReminder72H}
                        handleChange={event => {
                          updateCoach('coachReminder72H', event.target.checked);
                        }}
                      />
                      <SingleCheckboxInput
                        value={'coachReminder24H'}
                        label={t('preferences.24h')}
                        id={'24hCoach'}
                        checked={preferences.notifications.coach.coachReminder24H}
                        handleChange={event => {
                          updateCoach('coachReminder24H', event.target.checked);
                        }}
                      />
                      <SingleCheckboxInput
                        value={'coachReminder2H'}
                        label={t('preferences.2h')}
                        id={'2hCoach'}
                        checked={preferences.notifications.coach.coachReminder2H}
                        handleChange={event => {
                          updateCoach('coachReminder2H', event.target.checked);
                        }}
                      />
                    </fieldset>
                  </div>
                )}
                {showTrainee && (
                  <fieldset className={'mt-5'}>
                    <h4 className={'mb-3'}>{t('preferences.traineeScheduling')}</h4>
                    <SingleCheckboxInput
                      value={'coachCancelsSession'}
                      label={t('preferences.coachCancels')}
                      id={'coachCancels'}
                      checked={preferences.notifications.trainee.coachCancelsSession}
                      handleChange={event => {
                        updateTrainee('coachCancelsSession', event.target.checked);
                      }}
                    />
                    <SingleCheckboxInput
                      value={'traineeReminder72H'}
                      label={t('preferences.72h')}
                      id={'trainee72H'}
                      checked={preferences.notifications.trainee.traineeReminder72H}
                      handleChange={event => {
                        updateTrainee('traineeReminder72H', event.target.checked);
                      }}
                    />
                    <SingleCheckboxInput
                      value={'traineeReminder24H'}
                      label={t('preferences.24h')}
                      id={'trainee24H'}
                      checked={preferences.notifications.trainee.traineeReminder24H}
                      handleChange={event => {
                        updateTrainee('traineeReminder24H', event.target.checked);
                      }}
                    />
                    <SingleCheckboxInput
                      value={'traineeReminder2H'}
                      label={t('preferences.2h')}
                      id={'trainee2H'}
                      checked={preferences.notifications.trainee.traineeReminder2H}
                      handleChange={event => {
                        updateTrainee('traineeReminder2H', event.target.checked);
                      }}
                    />
                  </fieldset>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPreferences;
