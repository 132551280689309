import React, { useEffect, useState } from 'react';

import Header from './Header';
import { Outlet } from 'react-router-dom';

type LayoutPublicProps = {
  children?: React.ReactNode;
};

const LayoutPublic: React.FC<LayoutPublicProps> = ({ children }) => {
  return (
    <div className='bg-background flex justify-center min-h-screen pt-15vh pb-5vh'>
      <div className='container'>
        <Header />
        {children}
      </div>
    </div>
  );
};

export const LayoutPublicOutlet = () => {
  const [mounted, setMounted] = useState(true);

  useEffect(() => {
    return () => {
      setMounted(false);
    };
  }, []);

  if (!mounted) return null;

  return (
    <LayoutPublic>
      <Outlet />
    </LayoutPublic>
  );
};

export default LayoutPublic;
