import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

// FORM
import { useForm } from '../../hooks/useForm';
import { signinRequestValidation } from '../../validation/auth.validation';

// SERVICE
import { AuthService } from '../../service/auth.service';
import { SigninRequestDto } from '../../service/dto/auth.dto';

import { ifNoInstitutionSetDefault } from '../../utils/institution';
import { UserResponseDto } from '../../service/dto/user.dto';

// REDUX
import { actionSignin, actionToggleInstitution, signin, toggleInstitution } from '../../redux/actions';
import { useAppDispatch } from '../../redux/store';

// UI COMPONENTS
import PrimaryButton from '../../components/PrimaryButton';
import InputLabel from '../../components/InputLabel';
import EmailInput from '../../components/EmailInput';
import PasswordInput from '../../components/PasswordInput';
import MessageBlock from '../../components/MessageBlock';
import { WsType } from '../../common/interfaces';
import { WebSocketContext } from '../../context/socket';

enum COMPONENT_STATE {
  INIT,
  SUCCESS,
  FAIL,
  ACCOUNT_INACTIVE,
}

const Signin: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const socket: WsType | null = useContext(WebSocketContext);

  const [state, setState] = useState(COMPONENT_STATE.INIT);
  const signinUrl = 'signin';
  const redirectToSearchParam = '?redirectTo=';
  const querySearch = window.location.hash.substring(window.location.hash.indexOf(signinUrl) + signinUrl.length);
  let redirectTo: string;
  if (querySearch && querySearch.length) {
    redirectTo = querySearch.substring(redirectToSearchParam.length);
  }
  
  const navigateTo = (path: string = '/dashboard') => {
    navigate(path);
  }
  
  const {
    handleSubmit,
    handleChange,
    data: payload,
    errors,
  } = useForm<SigninRequestDto>({
    validations: signinRequestValidation,
    onSubmit: () => {
      AuthService.signin(
        payload,
        data => {
          // Redux Signin
          const user: UserResponseDto = { ...data.user };
          // Redux If no default institution set one
          ifNoInstitutionSetDefault(
            user,
            (defaultInstitution: any) => {
              dispatch(actionToggleInstitution(defaultInstitution));
              dispatch(actionSignin(user));
              socket?.coachSubscribeToAdHoc(user.id);
              navigateTo(redirectTo ?? '/dashboard');
            },
            () => {
              navigateTo(redirectTo ?? '/dashboard');
            },
            () => {
              navigateTo('/is-no-institution');
            },
          );
        },
        error => {
          const responseStatus = (error as any)?.response?.status;
          switch (responseStatus) {
            case 406:
              setState(COMPONENT_STATE.ACCOUNT_INACTIVE);
              break;
            default:
              setState(COMPONENT_STATE.FAIL);
              break;
          }
        },
      );
    },
  });

  return (
    <div className='flex justify-center'>
      <div className='w-112.5'>
        <div className='text-2xl font-bold my-10 text-center'>{t('auth.signin.title')}</div>
        {state == COMPONENT_STATE.FAIL && (
          <div className='mb-10'>
            <MessageBlock message={t('errorMessages.enteredIncorrectCreds')} type='errorBlock' />
          </div>
        )}
        {state == COMPONENT_STATE.ACCOUNT_INACTIVE && (
          <div className='mb-10'>
            <MessageBlock type='warningBlock' message={t('errorMessages.accountIsNotActivated')} />
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className='w-full mb-6'>
            <InputLabel label={t('inputLabels.email')} />
            <EmailInput
              placeholder={t('placeholders.email')}
              value={payload.email || ''}
              handleChange={handleChange('email')}
              error={errors.email}
              required
            />
          </div>
          <div className='w-full mb-10'>
            <InputLabel label={t('inputLabels.password')} />
            <PasswordInput
              placeholder={t('placeholders.password')}
              value={payload.password || ''}
              handleChange={handleChange('password')}
              error={errors.password}
              required
            />
          </div>

          <div className='w-full mb-12 flex justify-center'>
            <PrimaryButton title={t('buttons.signin')} type='submit' />
          </div>
        </form>
        <hr className='w-full h-px bg-black-divider border-0 mb-12' />
        <div className='text-center mb-12'>
          <Link className='text-primary underline' to='/password/forgot' replace>
            {t('auth.linkTo.forgotPassword')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Signin;
