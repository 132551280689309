import React from 'react';

import { useTranslation } from 'react-i18next';
import ArchivedTag from '../../components/ArchivedTag';

interface ITableProps {
  list: Array<any>;
  onDelete: Function;
  onEdit: Function;
}

const ScoringTable = ({ list, onDelete, onEdit }: ITableProps) => {
  const { t } = useTranslation();

  return (
    <table className='dataTable table-fixed w-full bg-white px-4'>
      <thead>
        <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter'>
          <td className='py-4 w-1/4'>{t('table.scoringItem')}</td>
          <td className='text-gray-dark font-bold py-4 w-1/5'>{t('table.category')}</td>
          <td className='text-gray-dark font-bold py-4 w-1/5'>{t('table.mnemonic')}</td>
          <td className='text-gray-dark font-bold py-4 w-1/12'>{t('table.grades')}</td>
          <td className='text-gray-dark font-bold py-4 w-1/12'>{t('table.weight')}</td>
          <td className='text-gray-light py-4 w-1/5 xl:w-2/12'>{t('table.actions')}</td>
        </tr>
      </thead>
      <tbody>
        {list.map((i, j) => {
          return (
            <tr className='border border-solid border-black-divider' key={'item_' + j}>
              <td className='text-gray-dark font-bold py-4'>
                {i.name} {i.archived && <ArchivedTag />}
              </td>
              <td className='text-gray-dark py-4'>{i.categoryName}</td>
              <td className='uppercase text-gray-dark py-4'>{i.mnemonic}</td>
              <td className='uppercase text-gray-dark py-4'>{i.grades}</td>
              <td className='uppercase text-gray-dark py-4'>{i.weight}</td>
              <td className='py-4 flex flex-wrap'>
                <span className='text-primary cursor-pointer pr-2' onClick={() => onEdit(j)}>
                  {t('buttons.edit')}
                </span>
                {/* <span className='text-negative-darker cursor-pointer pr-2' onClick={() => onDelete(j)}>{t('buttons.delete')}
                </span> */}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ScoringTable;
