import React, { MouseEvent, ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  actionSetSessionTimePlaying,
  actionSetVideoTitleConfig,
  setSessionTimePlaying,
  setVideoTitleConfig,
} from '../redux/actions';

// DTO
import {
  ScoreItemDto,
  ScoreResultDto,
  SessionRecordSequenceDto,
  TeachableMomentDto,
  TeachableMomentForTimelineDto,
} from '../service/dto/session.dto';

import { DurationMS, PercentageMultiplier, scoreColors } from '../utils/constants';
import { ScoreTypes } from '../common/enums';
import { RecordSequenceRange, timeSequencesToRanges } from '../utils/funcs';
import { useAppDispatch } from '../redux/store';

interface ITimelineProps {
  scoreResult: ScoreResultDto;
  teachableMoments: TeachableMomentDto[];
  startTime: string;
  timeSequences: SessionRecordSequenceDto[];
  videoTime: number;
  videoRef: RefObject<HTMLVideoElement>;
  isReadonly: boolean;
  timeUpdateCallback: (time: number) => void;
  watchTimeUpdate?: number;
}

interface ITimelineVideoState {
  isPaused: boolean;
  isSeeking: boolean;
  isManipulated: boolean;
  isEnded: boolean;
  isMouseDown: boolean;
  timelineProgress: number;
  timelineCurrentTime: number;
  timelineTotalTime: number;
}

const defaultTimelineVideoState: ITimelineVideoState = {
  isPaused: true,
  isSeeking: false,
  isManipulated: false,
  isEnded: false,
  isMouseDown: false,
  timelineProgress: 0,
  timelineCurrentTime: 0,
  timelineTotalTime: 0,
};

const TICK_TIME = 100;
const TIME_MIN_SYMBOLS_TO_DISPLAY = 10;

const Timeline = ({
  scoreResult,
  teachableMoments,
  startTime,
  timeSequences,
  videoTime,
  videoRef,
  isReadonly,
  timeUpdateCallback,
  watchTimeUpdate,
}: ITimelineProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const timelineRef = useRef<HTMLDivElement>(null);
  const [videoState, _setVideoState] = useState<ITimelineVideoState>({
    ...defaultTimelineVideoState,
    timelineTotalTime: videoTime * DurationMS.SEC,
  });
  const videoStateRef = useRef(videoState);
  const setVideoState = (state: Partial<ITimelineVideoState>) => {
    videoStateRef.current = { ...videoStateRef.current, ...state };
    _setVideoState(videoStateRef.current);
  };
  const [progressToTimeline, _setProgressToTimeline] =
    useState<Map<number, ScoreItemDto | TeachableMomentForTimelineDto>>();
  const progressToTimelineRef = useRef(progressToTimeline);
  const setProgressToTimeline = (val: Map<number, ScoreItemDto | TeachableMomentForTimelineDto>) => {
    progressToTimelineRef.current = val;
    _setProgressToTimeline(val);
  };
  const [selectedScoreItemTimestamp, setSelectedScoreItemTimestamp] = useState<string>('');
  const [filteredScoreResults, setFilteredScoreResults] = useState<ScoreResultDto>({});
  let onTickIntervalId: NodeJS.Timeout;

  const onVideoPaused = () => setVideoState({ isPaused: true });
  const onVideoResumed = (event: Event) => {
    setVideoState({ isPaused: false });
    updateTimelineProgress((event.target as HTMLVideoElement).currentTime * DurationMS.SEC);
  };
  const onVideoSeeking = () => setVideoState({ isSeeking: true });
  const onVideoSeeked = () => setVideoState({ isSeeking: false });
  const onVideoLoaded = () => updateTitleWithClosestScoreResult();
  const onInteractiveStart = () => {
    window.addEventListener('mouseup', onInteractiveEnd);
    window.addEventListener('touchend', onInteractiveEnd);
    document.body.style.userSelect = 'none';
    setVideoState({ isManipulated: true });
  };

  const onInteractiveEnd = () => {
    window.removeEventListener('mouseup', onInteractiveEnd);
    window.removeEventListener('touchend', onInteractiveEnd);
    document.body.style.userSelect = 'text';
    setVideoState({ isManipulated: false });
  };

  const updateTimelineProgress = (newTime: number) => {
    if (!videoStateRef.current) {
      return;
    }

    updateTitleWithClosestScoreResult();
    setVideoState({
      timelineCurrentTime: newTime,
      timelineProgress: (newTime / videoStateRef.current.timelineTotalTime) * PercentageMultiplier,
    });
  };

  const onInteractiveMove = (event: MouseEvent | TouchEvent) => {
    if (!timelineRef.current || !videoStateRef.current.isManipulated) {
      return;
    }

    dragTimeline(event.type === 'mousemove' ? (event as MouseEvent).clientX : (event as TouchEvent).touches[0].clientX);
  };

  const onScoreSelect = (event: MouseEvent | TouchEvent) => {
    if (!timelineRef.current) {
      return;
    }

    dragTimeline(event.type === 'click' ? (event as MouseEvent).clientX : (event as TouchEvent).touches[0].clientX);
  };

  const updateTitleWithClosestScoreResult = () => {
    if (!videoStateRef.current || !progressToTimelineRef.current || !progressToTimelineRef.current.size) {
      return;
    }

    const currentProgress = videoStateRef.current.timelineProgress;
    let closestKey;
    let closestAbs: number | null = null;
    progressToTimelineRef.current.forEach((_, key) => {
      const iteratedKeyAbs = Math.abs(currentProgress - key);
      if (closestAbs === null || iteratedKeyAbs < closestAbs) {
        closestAbs = iteratedKeyAbs;
        closestKey = key;
      }
    });

    if (closestKey != null && progressToTimelineRef.current.has(closestKey)) {
      const closest = progressToTimelineRef.current.get(closestKey)!;
      const closestIndex = Array.from(progressToTimelineRef.current.keys()).indexOf(closestKey);
      setSelectedScoreItemTimestamp(closest.tmstmp);
      dispatch(
        actionSetVideoTitleConfig({
          name: closest.name,
          value: closest.value,
          index: closestIndex,
        }),
      );
    }
  };

  const dragTimeline = (cursorAbsolutePositionX: number) => {
    if (!timelineRef.current || !videoRef.current || isReadonly) {
      return;
    }

    const startTimelinePosition = timelineRef.current.getBoundingClientRect().left;
    const timelineTotalWidth = timelineRef.current.clientWidth;
    const cursorX = cursorAbsolutePositionX - startTimelinePosition;
    const newTime = Math.round((cursorX / timelineTotalWidth) * videoStateRef.current.timelineTotalTime);
    updateTime(newTime);
  };

  const updateTime = (newTimeInMS: number, triggerCallback = true) => {
    if (!videoRef.current) {
      return;
    }

    updateTimelineProgress(newTimeInMS);
    updateTitleWithClosestScoreResult();
    videoRef.current.currentTime = newTimeInMS / DurationMS.SEC;
    triggerCallback && timeUpdateCallback && timeUpdateCallback(newTimeInMS / DurationMS.SEC);
  };

  const onTick = () => {
    const { isEnded, isManipulated, isPaused, isSeeking, isMouseDown, timelineCurrentTime } = videoStateRef.current;
    if (isEnded || isManipulated || isPaused || isSeeking || isMouseDown) {
      return;
    }

    if (!timelineRef.current) {
      return;
    }

    updateTimelineProgress(timelineCurrentTime + TICK_TIME);
  };

  const getTitleTimeString = (): ReactNode => {
    const totalTimeMS = videoState.timelineTotalTime;
    const currentTimeMS = Math.max(
      0,
      Math.min((videoState.timelineProgress * totalTimeMS) / PercentageMultiplier, totalTimeMS),
    );

    const totalTimeMin = Math.floor(totalTimeMS / DurationMS.MIN);
    const totalTimeSec = Math.floor((totalTimeMS - totalTimeMin * DurationMS.MIN) / DurationMS.SEC);

    const currentTimeMin = Math.floor(currentTimeMS / DurationMS.MIN);
    const currentTimeSec = Math.floor((currentTimeMS - currentTimeMin * DurationMS.MIN) / DurationMS.SEC);

    const totalTimeMinToDisplay =
      totalTimeMin < 10 ? `0${totalTimeMin}`.slice(-TIME_MIN_SYMBOLS_TO_DISPLAY) : totalTimeMin.toString();
    const totalTimeSecToDisplay =
      totalTimeSec < 10 ? `0${totalTimeSec}`.slice(-TIME_MIN_SYMBOLS_TO_DISPLAY) : totalTimeSec.toString();

    const currentTimeMinToDisplay =
      currentTimeMin < 10 ? `0${currentTimeMin}`.slice(-TIME_MIN_SYMBOLS_TO_DISPLAY) : currentTimeMin.toString();
    const currentTimeSecToDisplay =
      currentTimeSec < 10 ? `0${currentTimeSec}`.slice(-TIME_MIN_SYMBOLS_TO_DISPLAY) : currentTimeSec.toString();

    return `${currentTimeMinToDisplay}:${currentTimeSecToDisplay} / ${totalTimeMinToDisplay}:${totalTimeSecToDisplay}`;
  };

  useEffect(() => {
    if (videoRef.current) {
      const { current } = videoRef;
      current.addEventListener('pause', onVideoPaused);
      current.addEventListener('play', onVideoResumed);
      current.addEventListener('seeking', onVideoSeeking);
      current.addEventListener('seeked', onVideoSeeked);
      current.addEventListener('loadeddata', onVideoLoaded);
    }
    onTickIntervalId = setInterval(onTick, TICK_TIME);
    updateTitleWithClosestScoreResult();

    return () => {
      if (videoRef.current) {
        const { current } = videoRef;
        current.removeEventListener('pause', onVideoPaused);
        current.removeEventListener('play', onVideoResumed);
        current.removeEventListener('seeking', onVideoSeeking);
        current.removeEventListener('seeked', onVideoSeeked);
        current.removeEventListener('loadeddata', onVideoLoaded);
      }
      clearInterval(onTickIntervalId);
    };
  }, [videoRef]);

  useEffect(() => {
    let timeRanges: RecordSequenceRange[];

    if (!timeSequences || !timeSequences.length) {
      if (startTime) {
        const from = new Date(startTime).getTime();
        const to = from + (videoTime * 1000);
        timeRanges = [{
          from,
          to,
          fromPercent: 0,
          toPercent: 100,
        }];
      } else {
        setFilteredScoreResults({});
        return;
      }
    } else {
      timeRanges = timeSequencesToRanges(timeSequences, videoTime);
    }

    let updatedProgressToItem = new Map<number, ScoreItemDto | TeachableMomentForTimelineDto>();

    if (scoreResult !== null) {
      for (const _scoreItem of Object.values(scoreResult).filter(x => x != null && x.value != null)) {
        const timestamp = new Date(_scoreItem.tmstmp).getTime();
        const matchedRange = timeRanges.find(tr => timestamp >= tr.from && timestamp <= tr.to);
        if (!matchedRange) {
          continue;
        }

        const progress =
          ((timestamp - matchedRange.from) / (matchedRange.to - matchedRange.from)) *
            (matchedRange.toPercent - matchedRange.fromPercent) +
          matchedRange.fromPercent;
        updatedProgressToItem.set(progress, _scoreItem);
      }
    }
    if (teachableMoments && teachableMoments.length > 0) {
      for (const _teachableMomentItem of teachableMoments) {
        const timestamp = new Date(_teachableMomentItem.tmstmp).getTime();
        const matchedRange = timeRanges.find(tr => timestamp >= tr.from && timestamp <= tr.to);
        if (!matchedRange) {
          continue;
        }

        const progress =
          ((timestamp - matchedRange.from) / (matchedRange.to - matchedRange.from)) *
            (matchedRange.toPercent - matchedRange.fromPercent) +
          matchedRange.fromPercent;
        updatedProgressToItem.set(progress, {
          name: _teachableMomentItem.text,
          value: 0.5,
          tmstmp: _teachableMomentItem.tmstmp,
        });
      }
    }

    if (scoreResult !== null && teachableMoments && teachableMoments.length > 0) {
      updatedProgressToItem = new Map([...updatedProgressToItem.entries()].sort((a, b) => (a[0] > b[0] ? 1 : -1)));
    }

    setProgressToTimeline(updatedProgressToItem);
  }, [scoreResult]);

  useEffect(() => {
    if (watchTimeUpdate) {
      updateTime(watchTimeUpdate * DurationMS.SEC, false);
    }
  }, [watchTimeUpdate]);

  useEffect(() => {
    dispatch(actionSetSessionTimePlaying({ selectedScoreItemTimestamp }));
  }, [selectedScoreItemTimestamp]);

  return (
    <div className='timeline'>
      <div className='timeline-time leading-4 text-3.25 text-black m-0'>
        <span>
          {t('record_modal.timeline.title')} - {getTitleTimeString()}
        </span>
      </div>
      <div
        ref={timelineRef}
        className='relative cursor-pointer bg-primary-lightest h-2 rounded-lg mt-3'
        onClick={e => dragTimeline(e.clientX)}
        onMouseDown={onInteractiveStart}
        onTouchStart={event => {
          onInteractiveStart();
          dragTimeline(event.touches[0].clientX);
        }}
        onMouseMove={onInteractiveMove}
      >
        <div
          style={{ width: `${videoStateRef.current.timelineProgress}%` }}
          className='bg-gray rounded-l-lg progressTime max-w-full'
        />
        {progressToTimeline &&
          Object.values(
            Array.from(progressToTimeline).map(([left, el]) => (
              <div
                key={el.name}
                className={
                  'absolute z-10 left-0 border-3 border-solid rounded-full ' +
                  (selectedScoreItemTimestamp === el.tmstmp
                    ? 'w-4.5 h-4.5 -top-1.5 border-primary'
                    : 'w-3 h-3 -top-0.6 border-gray')
                }
                style={{
                  backgroundColor: scoreColors.get(el.value) || scoreColors.get(ScoreTypes.None),
                  left: `${left}%`,
                }}
                onClick={onScoreSelect}
              />
            )),
          )}
      </div>
    </div>
  );
};

export default Timeline;
