import { IRouteComponent } from '../../routes';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import SearchField from '../../components/SearchField';
import AbstractModal from '../../components/AbstractModal';
import ModalContent from '../../components/ModalContent';
import { CategoryCreateDto, CategoryListItemDto } from '../../service/dto/category.dto';
import { CategoryService } from '../../service/category.service';
import Alert from '../../utils/alert';
import CategoryTable from './CategoryTable';
import CategoryCreate from './CategoryCreate';
import DefaultHeader from '../../components/DefaultHeader';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { SearchQueryDto } from '../../service/dto/general';
import { setCategorySearchSelector } from '../../redux/actions';
import SingleCheckboxInput from '../../components/SingleCheckboxInput';
import { DEFAULT_CHECKBOX_STYLE } from '../../utils/default-styles';

enum COMPONENT_STATE {
  LIST,
  CREATE,
  EDIT,
}

const CategoryList = ({ currentUser, currentInstitution }: IRouteComponent) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [state, setState] = useState(COMPONENT_STATE.LIST);
  const [categoryList, setCategoryList] = useState<CategoryListItemDto[]>([]);
  const [nameValidation, setNameValidation] = useState('');
  const [category, setCategory] = useState<CategoryListItemDto | CategoryCreateDto>({ name: '', description: '' });
  const { searchCategory }: { searchCategory: string } = useAppSelector((state: any) => state.categorySearchReducer);

  const [showArchived, setShowArchived] = useState<boolean>(false);

  const onSearch = (search: string) => {
    dispatch(setCategorySearchSelector({ searchCategory: search }) as any);
  };

  useEffect(() => {
    fetchCategories();
  }, [searchCategory, showArchived, searchCategory]);

  const fetchCategories = () => {
    const query: SearchQueryDto = {
      search: searchCategory,
      archived: showArchived,
    };
    CategoryService.list(
      query,
      (data: CategoryListItemDto[]) => {
        setCategoryList([...data]);
      },
      () => {
        setCategoryList([]);
      },
    );
  };

  const createCategory = (category: CategoryCreateDto) => {
    CategoryService.create(
      category,
      (data: CategoryListItemDto) => {
        setCategoryList([...categoryList, data]);
        Alert.success(t('successMessages.categoryCreated'));
        setState(COMPONENT_STATE.LIST);
      },
      () => {
        Alert.warning(t('errorMessages.categoryCreateError'));
      },
    );
  };

  const editCategory = (category: CategoryListItemDto) => {
    CategoryService.update(
      category,
      (data: CategoryListItemDto) => {
        fetchCategories();
        Alert.success(t('successMessages.categoryEdited'));
        setState(COMPONENT_STATE.LIST);
      },
      () => {
        Alert.warning(t('errorMessages.categoryEditError'));
      },
    );
  };

  const handleNameChange = (value: string) => {
    if (category) {
      if (value.length < 5) {
        setNameValidation('category.validationError.name');
      } else {
        setNameValidation('');
      }
      let newCategory = Object.assign({}, category);
      newCategory.name = value;
      setCategory(newCategory);
    }
  };

  const handleDescriptionChange = (value: string) => {
    if (category) {
      let newCategory = Object.assign({}, category);
      newCategory.description = value;
      setCategory(newCategory);
    }
  };

  const handleSubmit = () => {
    if (nameValidation) {
      return;
    }
    if (state === COMPONENT_STATE.CREATE) {
      createCategory(category);
    }
    if (state === COMPONENT_STATE.EDIT) {
      editCategory(category as CategoryListItemDto);
    }
  };

  const title = t('category.list.title') + ' (' + categoryList.length + ')';

  const archivedFilterBar = () => {
    return (
      <div className='pt-3 pr-1 flex justify-end'>
        <SingleCheckboxInput
          id={t('common.filterShowArchived')}
          label={t('common.filterShowArchived')}
          handleChange={() => setShowArchived(!showArchived)}
          checked={showArchived}
          style={DEFAULT_CHECKBOX_STYLE}
        />
      </div>
    );
  };

  const searchBar = () => {
    return (
      <div className='p-6'>
        <div className='flex items-center'>
          <div className='mr-6'>
            <SearchField onSearch={onSearch} value={searchCategory} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      <DefaultHeader
        title={title}
        primaryAction={{
          title: t('category.list.createNew'),
          onClick: () => {
            setState(COMPONENT_STATE.CREATE);
          },
        }}
      />

      {currentUser.isSuperAdmin && archivedFilterBar()}
      {searchBar()}
      <CategoryTable
        list={categoryList}
        onEdit={(index: number) => {
          setCategory(categoryList[index]);
          setState(COMPONENT_STATE.EDIT);
        }}
      />

      {(state == COMPONENT_STATE.CREATE || state == COMPONENT_STATE.EDIT) && (
        <AbstractModal
          label={
            state == COMPONENT_STATE.CREATE
              ? t('category.list.modal.title-create')
              : t('category.list.modal.title-edit')
          }
          leftBtn={{
            label: t('buttons.cancel'),
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
          rightBtn={{
            label: t('buttons.save'),
            icon: 'bi-check2-circle',
            onClick: () => {
              handleSubmit();
            },
          }}>
          <CategoryCreate
            category={category}
            errorName={nameValidation}
            handleChangeName={handleNameChange}
            handleChangeDescription={handleDescriptionChange}
          />
        </AbstractModal>
      )}
    </div>
  );
};

export default CategoryList;
