import React, { useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  InsightsEvaluationCaseResponseDto,
  InsightsEvaluationCoachResponseDto,
  InsightsEvaluationSystemDto,
  InsightsEvaluationTraineeResponseDto,
} from '../../service/dto/insights.dto';
import ReactPaginate from 'react-paginate';
import AbstractModal from '../../components/AbstractModal';

export interface EvaluationTabsTabContent {
  title: string;
  trainee?: InsightsEvaluationTraineeResponseDto[];
  coach?: InsightsEvaluationCoachResponseDto[];
  cases?: InsightsEvaluationCaseResponseDto;
  system?: InsightsEvaluationSystemDto[];
  forcePage: number;
  pageCount: number;
  onPageChange: (event: { selected: number }) => void;
}

const EvaluationTabs = ({
  tabs,
  setCurrentTabIndex,
  currentTabIndex,
}: {
  tabs: EvaluationTabsTabContent[];
  setCurrentTabIndex: (index: number) => unknown;
  currentTabIndex: number;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');

  const traineeDetails = (id: string, number: number) => {
    navigate(`/trainee/detail/` + id);
  };

  const coachDetails = (id: string, number: number) => {
    navigate(`/coach/detail/` + id);
  };

  return (
    <Tabs selectedIndex={currentTabIndex} onSelect={(index: number) => !!setCurrentTabIndex(index)}>
      <TabList className='flex justify-start space-x-8 text-black border-b border-separate text-lg select-none font-bold pl-7'>
        {tabs.map(({ title }, index) => {
          return (
            <Tab className={`cursor-pointer ${currentTabIndex == index ? 'text-primary' : ''}`} key={title + index}>
              <div>{title}</div>
              <div className={'h-4'} />
              <div className={`h-1 w-full bg-primary rounded-full ${currentTabIndex == index ? '' : 'opacity-0'}`} />
            </Tab>
          );
        })}
      </TabList>
      {tabs.map(({ title, trainee, coach, cases, system, pageCount, forcePage, onPageChange }, index) => {
        return (
          <TabPanel key={`evaluation-tab-panes-${index}`}>
            {trainee && (
              <div>
                <table className='dataTable table-fixed w-full bg-white px-4'>
                  <thead>
                    <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter text-base'>
                      <td className='text-gray-dark font-bold py-4 w-1/5'>{t('table.name')}</td>
                      <td className='text-gray-dark font-bold py-4 w-1/4'>{t('table.email')}</td>
                      <td className='text-gray-dark font-bold py-4 w-1/12'>{t('table.score')}</td>
                      <td className='text-gray-dark font-bold py-4 w-1/8'>{t('table.evaluation')}</td>
                      <td className='text-gray-dark font-bold py-4'>{t('table.sessions')}</td>
                      <td className='text-gray-dark font-bold py-4'>{t('table.lastSession')}</td>
                      <td className='text-gray-dark font-bold py-4'>{t('table.actions')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {trainee.map((value, indexTrainee) => (
                      <tr className='border border-solid border-black-divider text-base' key={indexTrainee}>
                        <td className='text-gray-dark py-4'>{value.userFirstName + ' ' + value.userLastName}</td>
                        <td className='text-gray-dark py-4 '>{value.email}</td>
                        <td className='text-gray-dark py-4 '>
                          {value.score != null || undefined ? Math.round(value.score * 100) + '%' : 'n/A'}
                        </td>
                        <td className='text-gray-dark py-4 '>
                          {value.evaluation != null ? Math.round(value.evaluation * 10) + '%' : 'n/A'}
                        </td>
                        <td className='text-gray-dark py-4 '>{value.sessions != 0 ? value.sessions : 'n/A'}</td>
                        <td className='text-gray-dark py-4 '>
                          {value.lastSession != null ? value.lastSession : 'n/A'}
                        </td>
                        <td>
                          <span
                            className='text-primary cursor-pointer pr-4'
                            onClick={() => {
                              traineeDetails(value.id, indexTrainee);
                            }}>
                            <i className='bi bi-eye-fill text-primary-hover pr-1 text-base' />{' '}
                            {t('buttons.session_details')}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ReactPaginate
                  pageCount={pageCount}
                  containerClassName={'pagination flex justify-center text-gray-dark'}
                  previousLabel={<i className='bi bi-chevron-left' />}
                  breakLabel={'...'}
                  nextLabel={<i className='bi bi-chevron-right' />}
                  onPageChange={onPageChange}
                  pageRangeDisplayed={5}
                  pageClassName={'page-item'}
                  pageLinkClassName='page-link'
                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                  activeClassName='active'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  forcePage={forcePage}
                />
              </div>
            )}
            {coach && (
              <div>
                <table className='dataTable table-fixed w-full bg-white px-4'>
                  <thead>
                    <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter text-base'>
                      <td className='text-gray-dark font-bold py-4 w-1/5'>{t('table.name')}</td>
                      <td className='text-gray-dark font-bold py-4 w-1/4'>{t('table.email')}</td>
                      <td className='text-gray-dark font-bold py-4 w-1/8'>{t('table.evaluation')}</td>
                      <td className='text-gray-dark font-bold py-4'>{t('table.sessions')}</td>
                      <td className='text-gray-dark font-bold py-4'>{t('table.lastSession')}</td>
                      <td className='text-gray-dark font-bold py-4'>{t('table.actions')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {coach &&
                      coach.map((value, indexTrainee) => (
                        <tr className='border border-solid border-black-divider' key={indexTrainee}>
                          <td className='text-gray-dark py-4'>{value.userFirstName + ' ' + value.userLastName}</td>
                          <td className='text-gray-dark py-4'>{value.email}</td>
                          <td className='text-gray-dark py-4'>
                            {value.evaluation != null ? Math.round(value.evaluation * 10) + '%' : 'n/A'}
                          </td>
                          <td className='text-gray-dark py-4'>{value.sessions != 0 ? value.sessions : 'n/A'}</td>
                          <td className='text-gray-dark py-4'>
                            {value.lastSession != null ? value.lastSession : 'n/A'}
                          </td>
                          <td>
                            <span
                              className='text-primary cursor-pointer pr-4'
                              onClick={() => {
                                coachDetails(value.id, indexTrainee);
                              }}>
                              <i className='bi bi-eye-fill text-primary-hover pr-1' /> {t('buttons.session_details')}
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <ReactPaginate
                  pageCount={pageCount}
                  containerClassName={'pagination flex justify-center text-gray-dark'}
                  previousLabel={<i className='bi bi-chevron-left' />}
                  breakLabel={'...'}
                  nextLabel={<i className='bi bi-chevron-right' />}
                  onPageChange={onPageChange}
                  pageRangeDisplayed={5}
                  pageClassName={'page-item'}
                  pageLinkClassName='page-link'
                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                  activeClassName='active'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  forcePage={forcePage}
                />
              </div>
            )}
            {cases && cases.details.length == 0 && (
              <div className='bg-ghostwhite m-5 py-8 mt-0'>
                <p className='text-center text-xl'>{t('insights.evaluation.noResultsToDisplay')}</p>
              </div>
            )}
            {cases && cases.details.length != 0 && (
              <>
                <div className='bg-ghostwhite m-5 py-2 mt-0'>
                  <div className='m-5 flex justify-between border-b-2 text-gray-dark'>
                    <i className='bi bi-person-lines-fill text-2xl py-3 pl-3' />
                    <p className='font-bold py-4 w-1/5 flex-auto pl-5'>Average quality of case overall</p>
                    <p className='font-bold py-4 w-1/5 '>{cases.averageQualityOverall.toFixed(1)}</p>
                  </div>
                  <div className='m-5 flex justify-between border-b-2 text-gray-dark'>
                    <i className='bi bi-check-lg text-2xl py-3 pl-3' />
                    <p className='font-bold py-4 w-1/5 flex-auto pl-5'>Average quality of case material</p>
                    <p className='font-bold py-4 w-1/5 '>{cases.averageQualityMaterial.toFixed(1)}</p>
                  </div>
                  <div className='m-5 flex justify-between border-b-2 text-gray-dark'>
                    <i className='bi bi-hourglass text-2xl py-3 pl-3' />
                    <p className='font-bold py-4 w-1/5 flex-auto pl-5'>Average quality of scoring items</p>
                    <p className='font-bold py-4 w-1/5 '>{cases.averageQualityScoringItems.toFixed(1)}</p>
                  </div>
                </div>
                <table className='dataTable table-fixed w-full bg-white px-4'>
                  <thead>
                    <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter'>
                      <td className='text-gray-dark text-lg font-bold py-4 w-1/6'>{t('table.userName')}</td>
                      <td className='text-gray-dark text-lg font-bold py-4 w-1/8'>{t('table.role')}</td>
                      <td className='text-gray-dark text-lg font-bold py-4'>{t('table.caseOverall')}</td>
                      <td className='text-gray-dark text-lg font-bold py-4'>{t('table.caseMaterial')}</td>
                      <td className='text-gray-dark text-lg font-bold py-4'>{t('table.scoringItems')}</td>
                      <td className='text-gray-dark text-lg font-bold py-4'>{t('table.comment')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {cases &&
                      cases.details.map((value, indexCase) => (
                        <tr className='border border-solid border-black-divider' key={indexCase}>
                          <td className='text-gray-dark py-4 text-lg'>
                            {value.userFirstName + ' ' + value.userLastName}
                          </td>
                          <td className='text-gray-dark py-4 text-lg'>{value.role}</td>
                          <td className='text-gray-dark py-4 text-lg'>
                            {value.caseOverall != 0 ? value.caseOverall : 'n/A'}
                          </td>
                          <td className='text-gray-dark py-4 text-lg'>
                            {value.caseMaterial != null ? value.caseMaterial : 'n/A'}
                          </td>
                          <td className='text-gray-dark py-4 text-lg'>
                            {value.scoringItem != null ? value.scoringItem : 'n/A'}
                          </td>
                          <td>
                            {value.caseComment ? (
                              <span
                                className='text-primary cursor-pointer pr-4'
                                onClick={() => {
                                  setComment(value.caseComment);
                                  setOpenPopUp(true);
                                }}>
                                <i className='bi bi-eye-fill text-primary-hover pr-1 text-xl' />{' '}
                                {t('buttons.viewComment')}
                              </span>
                            ): t('table.noComment')}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <ReactPaginate
                  pageCount={pageCount}
                  containerClassName={'pagination flex justify-center text-gray-dark'}
                  previousLabel={<i className='bi bi-chevron-left' />}
                  breakLabel={'...'}
                  nextLabel={<i className='bi bi-chevron-right' />}
                  onPageChange={onPageChange}
                  pageRangeDisplayed={5}
                  pageClassName={'page-item'}
                  pageLinkClassName='page-link'
                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                  activeClassName='active'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  forcePage={forcePage}
                />
              </>
            )}
            {system && system.length == 0 && (
              <div className='bg-ghostwhite m-5 py-8 mt-0'>
                <p className='text-center text-xl'>{t('insights.evaluation.noResultsToDisplay')}</p>
              </div>
            )}
            {system && system.length != 0 && (
              <>
                <table className='dataTable table-fixed w-full bg-white px-4'>
                  <thead>
                    <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter'>
                      <td className='text-gray-dark font-bold py-4 w-1/4'>{t('table.userName')}</td>
                      <td className='text-gray-dark font-bold py-4 w-1/8'>{t('table.role')}</td>
                      <td className='text-gray-dark font-bold py-4'>{t('table.videoQuality')}</td>
                      <td className='text-gray-dark font-bold py-4'>{t('table.audioQuality')}</td>
                      <td className='text-gray-dark font-bold py-4'>{t('buttons.session_details')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {system &&
                      system.map((value, indexSystem) => (
                        <tr className='border border-solid border-black-divider' key={indexSystem}>
                          <td className='text-gray-dark py-4'>{value.userFirstName + ' ' + value.userLastName}</td>
                          <td className='text-gray-dark py-4'>{value.role}</td>
                          <td className='text-gray-dark py-4'>
                            {value.videoQuality != 0 ? value.videoQuality : 'n/A'}
                          </td>
                          <td className='text-gray-dark py-4'>
                            {value.audioQuality != null ? value.audioQuality : 'n/A'}
                          </td>
                          <td>
                            <span
                              className='text-primary cursor-pointer pr-4'
                              onClick={() => {
                                setComment(value.generalRemarks);
                                setOpenPopUp(true);
                              }}>
                              <i className='bi bi-eye-fill text-primary-hover pr-1' />{' '}
                              {t('session.result.evaluation.generalSectionTitle')}
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <ReactPaginate
                  pageCount={pageCount}
                  containerClassName={'pagination flex justify-center text-gray-dark'}
                  previousLabel={<i className='bi bi-chevron-left' />}
                  breakLabel={'...'}
                  nextLabel={<i className='bi bi-chevron-right' />}
                  onPageChange={onPageChange}
                  pageRangeDisplayed={5}
                  pageClassName={'page-item'}
                  pageLinkClassName='page-link'
                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                  activeClassName='active'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  forcePage={forcePage}
                />
              </>
            )}
            {openPopUp && (
              <AbstractModal
                position={{ width: 'w-1/2', left: 'left-1/3' }}
                label={
                  index == 2 ? t('insights.evaluation.caseComment') : t('session.result.evaluation.generalSectionTitle')
                }
                cancelButton={{
                  onClick: () => {
                    setOpenPopUp(false);
                  },
                }}>
                {comment}
              </AbstractModal>
            )}
          </TabPanel>
        );
      })}
    </Tabs>
  );
};

export default EvaluationTabs;
